import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import AdminLayout from "layouts/Admin.jsx";
import AuthLayout from "layouts/Auth.jsx";

import ScrollToTop from "components/ScrollToTop/ScrollToTop";
import { refreshState } from "redux/user/actions";
import { selectUserToken } from "redux/user/selectors";

import "./assets/css/App.css";
import "flatpickr/dist/themes/material_green.css";

const App = () => {
  const dispatch = useDispatch();

  const token = useSelector(selectUserToken) || localStorage.getItem("token");

  const refreshStateHandler = () => {
    const token = localStorage.getItem("token");
    const role = localStorage.getItem("role");
    const user = JSON.parse(localStorage.getItem("user")) || "";
    dispatch(refreshState({ token, user, role }));
  };

  useEffect(() => {
    refreshStateHandler();
  }, []);

  useEffect(() => {}, [token]);
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Switch>
          {token ? (
            <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
          ) : (
            <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
          )}
          {token ? (
            <Redirect from="/" exact to="/admin/list" />
          ) : (
            <Redirect from="/" exact to="/auth/login" />
          )}

          <Route>
            {token ? <Redirect to="/admin/notfound" /> : <Redirect to="/auth/notfound" />}
          </Route>
        </Switch>
      </BrowserRouter>
    </>
  );
};

export default App;

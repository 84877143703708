import toast from "react-hot-toast";

import {
  FETCH_PSYCHOLOGIST_EXPORT_DATA,
  FETCH_PSYCHOLOGIST_FAILURE,
  FETCH_PSYCHOLOGIST_REQUEST,
  FETCH_PSYCHOLOGIST_SUCCESS,
  SELECT_PSYCHOLOGIST
} from "./types";

import {
  getPsychologistApi,
  getActivatePsychologistApi,
  getDeactivatePsychologistApi,
  getPsychologistExportDataApi
} from "services/PsychologistServices";

export const fetchPsychologistRequest = () => {
  return {
    type: FETCH_PSYCHOLOGIST_REQUEST
  };
};

export const fetchPsychologistSuccess = (users, totalUsers) => {
  return {
    type: FETCH_PSYCHOLOGIST_SUCCESS,
    payload: { users, totalUsers }
  };
};

export const fetchPsychologistFailure = (error) => {
  return {
    type: FETCH_PSYCHOLOGIST_FAILURE,
    payload: error
  };
};

export const selectPsychologist = (psychologist) => {
  return {
    type: SELECT_PSYCHOLOGIST,
    payload: psychologist
  };
};

export const fetchPsychologistThunkAction = (params, onSuccess, onError) => {
  return async (dispatch) => {
    try {
      dispatch(fetchPsychologistRequest());
      const { data } = await getPsychologistApi(params);

      if (data.success !== true) {
        throw new Error(data.message);
      }
      dispatch(fetchPsychologistSuccess(data.data.data, data.data.total));
      onSuccess();
    } catch (error) {
      dispatch(fetchPsychologistFailure(error?.response?.data?.message));
      onError(error?.response?.data?.message);
    }
  };
};

const fetchPyschologistsExportData = (data) => {
  return {
    type: FETCH_PSYCHOLOGIST_EXPORT_DATA,
    payload: data
  };
};

export const fetchPsychologistsExportDataThunkAction = (onSuccess, onError) => {
  return async (dispatch) => {
    try {
      const { data } = await getPsychologistExportDataApi();

      if (data.success !== true) {
        throw new Error(data.message);
      }
      dispatch(fetchPyschologistsExportData(data.data.data));
    } catch (error) {
      // onError(error?.response?.data?.message);
    }
  };
};

export const activatePsychologistThunkAction = async (id, onSuccess, onError) => {
  return async (dispatch) => {
    try {
      const promise = getActivatePsychologistApi(id);

      toast.promise(promise, {
        loading: "Loading...",
        success: "Status updated successfully.",
        error: (error) => `${error?.response?.data?.message || error?.message}`
      });

      const { data } = await promise;

      if (data.success !== true) {
        throw new Error(data.message);
      }
      onSuccess();
    } catch (error) {
      onError(error?.response?.data?.message);
    }
  };
};

export const deactivatePsychologistThunkAction = async (id, onSuccess, onError) => {
  return async (dispatch) => {
    try {
      const promise = getDeactivatePsychologistApi(id);

      toast.promise(promise, {
        loading: "Loading...",
        success: "Status updated successfully.",
        error: (error) => `${error?.response?.data?.message || error?.message}`
      });

      const { data } = await promise;

      if (data.success !== true) {
        throw new Error(data.message);
      }
      onSuccess();
    } catch (error) {
      onError(error?.response?.data?.message);
    }
  };
};

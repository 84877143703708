import { combineReducers } from "redux";
import userReducer from "./user/reducer";
import testReducer from "./test/reducer";
import psychologistReducer from "./psychologists/reducer";
import patientReducer from "./patients/reducer";

const rootReducer = combineReducers({
  user: userReducer,
  test: testReducer,
  psychologist: psychologistReducer,
  patient: patientReducer
});

export default rootReducer;

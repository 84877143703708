/*eslint-disable*/
import React from "react";
import { debounce } from "lodash";
import { useHistory } from "react-router-dom";
import { Card, CardHeader, CardFooter, Table, Container, Row, Button } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import useViewport from "hooks/useViewport";

import Header from "components/Headers/Header.jsx";
// import Paginate from "components/Pagination/Paginate";
import Search from "components/Search/Search";
import Loader from "components/Loader/Loader";
import GoBackButton from "components/GoBackButton/GoBackButton";
import ExportButton from "components/ExportButton/ExportButton";
import NewPagination from "components/NewPagination/NewPagination";

import { selectPatients } from "redux/patients/selectors";
import { selectPsychologists } from "redux/psychologists/selectors";
import {
  fetchPatientsThunkAction,
  fetchPatientsExportDataThunkAction
} from "redux/patients/actions";
import { deletePatientThunkAction } from "redux/patients/actions";
import Swal from "sweetalert2";

const PatientsListAdminPage = () => {
  const [patientData, setPatientData] = React.useState([]);
  const [error, setError] = React.useState(null);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [postsPerPage] = React.useState(10);
  const [search, setsSearch] = React.useState("");
  const [middleSearch, setMiddleSearch] = React.useState("");
  const [exportedData, setExportedData] = React.useState([]);

  let history = useHistory();
  const { width } = useViewport();
  const dispatch = useDispatch();
  const { patients, isLoading, totalPatients, exportPatientData } = useSelector(selectPatients);
  const { selectedPsychologist } = useSelector(selectPsychologists);

  const onSuccess = () => {
    setError(null);
  };

  const onError = (error) => {
    setError(error);
  };

  // const paginate = (pageNum) => {
  //   setCurrentPage(pageNum);
  // };

  // const nextPage = () => {
  //   setCurrentPage((prePage) => prePage + 1);
  // };

  // const prevPage = () => {
  //   setCurrentPage((prePage) => prePage - 1);
  // };

  const onSearchHandler = (keyword) => {
    setCurrentPage(1);
    setsSearch(keyword);
  };

  const navigateToPatientDetailsPage = (id) => {
    history.push(`/admin/subject-details/${id}`);
  };

  React.useEffect(() => {
    window.onload = function () {
      history.replace("/admin/psychologists");
    };
  }, []);

  React.useEffect(() => {
    dispatch(
      fetchPatientsThunkAction(
        {
          search,
          limit: postsPerPage,
          page: currentPage
        },
        selectedPsychologist?.id,
        onSuccess,
        onError
      )
    );
  }, [currentPage, search]);

  React.useEffect(() => {
    setPatientData(patients);
  }, [patients, patients?.length]);

  const debouncedSave = React.useCallback(
    debounce((searchText) => {
      setsSearch(searchText);
      setCurrentPage(1);
    }, 700),
    [] // will be created only once initially
  );

  React.useEffect(() => {
    debouncedSave(middleSearch);
  }, [middleSearch]);

  React.useEffect(() => {
    dispatch(fetchPatientsExportDataThunkAction(selectedPsychologist?.id));
  }, []);

  React.useEffect(() => {
    const formatedData = exportPatientData.map((item) => ({
      name: `${item.lastName}${" "}${item?.firstName}${" "}${item?.middleName[0].toUpperCase()}`,
      dob: item.dob,
      gender: item.gender[0].toUpperCase() + item.gender.slice(1)
    }));

    const csvData = formatedData.reduce((acc, item) => {
      const { name, dob, gender } = item;
      acc.push([name, dob, gender].join(","));
      return acc;
    }, []);

    setExportedData(csvData);
  }, [exportPatientData]);

  const deletePatientHandler = async (id) => {
    try {
      const popupResponse = await Swal.fire({
        // background: "#283046",
        color: "#fff",
        html: `<h3>If the subject is deleted, then all the data associated with it will also be deleted.</h3>`,
        showCancelButton: true,
        cancelButtonColor: "red",
        confirmButtonColor: "#005DD6",
        confirmButtonText: "Yes",
        cancelButtonText: "No"
      });

      if (popupResponse.isConfirmed) {
        dispatch(
          deletePatientThunkAction(
            id,
            () => {
              dispatch(
                fetchPatientsThunkAction(
                  {
                    search,
                    limit: postsPerPage,
                    page: currentPage
                  },
                  selectedPsychologist?.id,
                  onSuccess,
                  onError
                )
              );
            },
            () => {}
          )
        );
      }
    } catch (error) {}
  };
  return (
    <>
      <Header />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card className="shadow" style={{ minHeight: "50vh" }}>
              <CardHeader
                className={
                  width < 768 ? "border-0" : "border-0 d-flex justify-content-between pb-0"
                }
              >
                <div
                  style={
                    width < 768
                      ? {
                          display: "flex",
                          justifyContent: "space-between",
                          marginBottom: "10px"
                        }
                      : {}
                  }
                >
                  <h3 className="mb-0 text-capitalize">
                    {patientData?.length > 0 ? patientData[0]?.psychologistName + "'s" : null}{" "}
                    Subjects
                  </h3>
                  {patientData?.length > 0 ? (
                    <ExportButton
                      fileName="Subjects.csv"
                      headers={["Name, Date of Birth,Gender"]}
                      fileData={exportedData}
                    >
                      Export Data
                    </ExportButton>
                  ) : (
                    " "
                  )}
                </div>
                <div>
                  <div className="mb-2 d-flex flex-end justify-content-end">
                    <GoBackButton />
                  </div>

                  <Search
                    onSubmitHandler={onSearchHandler}
                    search={middleSearch}
                    onChangeHandler={(value) => setMiddleSearch(value)}
                  />
                </div>
              </CardHeader>
              {isLoading ? (
                <React.Fragment>
                  <div className="post_error_loading">
                    <Loader />
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {error ? (
                    <React.Fragment>
                      <div className="post_error_loading">{error}</div>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Table className="align-items-center table-flush" responsive>
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Date of Birth</th>
                            <th scope="col">Gender</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {patientData?.length > 0 &&
                            patientData?.map((user) => {
                              return (
                                <tr key={user?.id}>
                                  <td
                                    onClick={() => navigateToPatientDetailsPage(user?.id)}
                                    style={{
                                      textTransform: "capitalize"
                                    }}
                                  >
                                    {user?.lastName} {user?.firstName}{" "}
                                    {user?.middleName[0].toUpperCase()}
                                  </td>
                                  <td onClick={() => navigateToPatientDetailsPage(user?.id)}>
                                    {user?.dob}
                                  </td>
                                  <td
                                    onClick={() => navigateToPatientDetailsPage(user?.id)}
                                    className="text-capitalize"
                                  >
                                    {user?.gender ? user?.gender : "Male"}
                                  </td>
                                  {patientData[0]?.deletedAt ? (
                                    <td>
                                      <i
                                        className="fas fa-trash"
                                        style={{
                                          color: "#ff88a0",
                                          transform: "scale(1.2)",
                                          cursor: "pointer"
                                        }}
                                      ></i>
                                    </td>
                                  ) : (
                                    <td>
                                      <i
                                        className="fas fa-trash"
                                        style={{
                                          color: "red",
                                          transform: "scale(1.2)",
                                          cursor: "pointer"
                                        }}
                                        onClick={() => deletePatientHandler(user?.id)}
                                      ></i>
                                    </td>
                                  )}
                                </tr>
                              );
                            })}
                        </tbody>
                      </Table>
                      {!error && patientData?.length === 0 && (
                        <div className="no_posts">No subjects found.</div>
                      )}
                      {patientData && patientData?.length > 0 && (
                        <CardFooter className="py-4">
                          <nav aria-label="..." className="post_footer">
                            <NewPagination
                              totalRecords={totalPatients}
                              setCurrentPage={setCurrentPage}
                              pageLimit={postsPerPage}
                              currentPage={currentPage}
                              pageNeighbours={1}
                            />
                            {/* <Paginate
                              currentPage={currentPage}
                              postsPerPage={postsPerPage}
                              totalPosts={totalPatients}
                              paginate={paginate}
                              nextPage={nextPage}
                              prevPage={prevPage}
                            /> */}
                          </nav>
                        </CardFooter>
                      )}
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default PatientsListAdminPage;

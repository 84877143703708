import React from "react";
import { Col, FormGroup, Input, Row } from "reactstrap";
import makeAnimated from "react-select/animated";
import { Box, Grid, Paper } from "@material-ui/core";

import { styles } from "../common/styles";
import { renderButton, renderText } from "../common/DisplayComponent";

const Step4 = ({
  stepCount,
  isEditPage,
  resultData,
  handleNext,
  handlePrev,
  handleOnclickNextButton,
  handleAnswerChange,
  handleScoreChange
}) => {
  const animatedComponents = makeAnimated();

  const onClickAnswerHandler = (questionId, answer, subjectAnswer, item) => {
    if (subjectAnswer === answer) {
      handleAnswerChange(questionId, "", item);
    } else {
      handleAnswerChange(questionId, answer, item);
    }
  };

  const optionPrefix = ["A", "B", "C", "D", "E", "F"];
  return (
    <Paper style={styles.steps}>
      <Box mt={2} mb={2}>
        {renderText({
          label: "Mixed Type",
          type: "h6",
          color: "textPrimary",
          align: "center"
        })}
      </Box>
      <Row>
        <Col sm="12">
          <div>
            <h3>{stepCount?.title}</h3>
          </div>
        </Col>
        <Col sm="12">
          <div>
            <p>{stepCount?.description}</p>
          </div>
        </Col>
      </Row>

      <hr style={{ margin: "0 0 10px 0" }} />

      <Row>
        {stepCount?.questions?.map((item, index) => {
          const questionAnswer = resultData?.find(
            (answer) => answer.id === item?.id
          )?.subjectAnswer;
          return (
            <React.Fragment key={item?.id}>
              <Col sm="10">
                <div>
                  <p>
                    <b>{item?.title}</b>
                  </p>
                </div>
              </Col>

              {item?.type === "text" ? (
                <>
                  <Col sm={12}>
                    <FormGroup>
                      <Input
                        className="form-control-alternative"
                        required
                        onChange={(e) => handleAnswerChange(item?.id, e.target.value, item)}
                        value={
                          resultData.find((question) => question.id === item?.id)?.subjectAnswer
                        }
                        id="input-cabinet-title"
                        placeholder="Type Answer"
                        type="textarea"
                      />
                    </FormGroup>
                  </Col>

                  {isEditPage && (
                    <>
                      <Col sm={8}>
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-cabinet-title">
                            Item Score
                          </label>
                          <Input
                            className="form-control-alternative"
                            min={0}
                            max={resultData.find((question) => question.id === item?.id)?.score}
                            onChange={(e) => {
                              if (
                                Number(e.target.value) <=
                                resultData.find((question) => question.id === item?.id)?.score
                              ) {
                                handleScoreChange(item?.id, Number(e.target.value));
                              }
                            }}
                            onWheel={(e) => e.target.blur()}
                            value={
                              resultData.find((question) => question.id === item?.id)?.subjectScore
                            }
                            id="input-cabinet-title"
                            type="number"
                            step="0.01"
                          />
                        </FormGroup>
                      </Col>
                      <Col sm={4}>
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-cabinet-title">
                            Max Score
                          </label>
                          <Input
                            className="form-control-alternative"
                            disabled
                            value={resultData.find((question) => question.id === item?.id)?.score}
                            id="input-cabinet-title"
                            type="number"
                          />
                        </FormGroup>
                      </Col>
                    </>
                  )}
                </>
              ) : null}

              {item?.type !== "text" &&
                item?.options?.map((option, index) => {
                  return (
                    <React.Fragment key={item?.id + option?.id}>
                      <Col
                        sm={8}
                        onClick={() =>
                          onClickAnswerHandler(item?.id, option?.value, questionAnswer, item)
                        }
                      >
                        <div
                          className={
                            questionAnswer === option?.value ? "option_item_active" : "option_item"
                          }
                        >
                          <div className="option_prefix">{optionPrefix[index]}</div>
                          {option?.value?.charAt(0).toUpperCase() + option?.value?.slice(1)}
                        </div>
                      </Col>
                    </React.Fragment>
                  );
                })}
              <Col xs={12}>
                <hr />
              </Col>
            </React.Fragment>
          );
        })}
      </Row>

      <Grid container component={Box} justify="flex-end" mt={2} p={2}>
        {stepCount?.prev && (
          <Box ml={2}>
            {renderButton({
              label: "Back",
              color: "default",
              onClick: handlePrev
            })}
          </Box>
        )}
        {stepCount?.next && (
          <Box ml={2}>
            {renderButton({
              label: "Next",
              onClick: () => {
                handleNext(stepCount);
                handleOnclickNextButton(stepCount);
              }
            })}
          </Box>
        )}
        {stepCount?.submit && (
          <Box ml={2}>{renderButton({ label: "Submit", onClick: () => {}, type: "submit" })}</Box>
        )}
      </Grid>
    </Paper>
  );
};

export default Step4;

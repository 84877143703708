import {
  FETCH_PSYCHOLOGIST_EXPORT_DATA,
  FETCH_PSYCHOLOGIST_FAILURE,
  FETCH_PSYCHOLOGIST_REQUEST,
  FETCH_PSYCHOLOGIST_SUCCESS,
  SELECT_PSYCHOLOGIST
} from "./types";

const initialState = {
  isLoading: false,
  error: "",
  psychologists: [],
  totalPsychologists: 0,
  selectedPsychologist: null,
  exportPsychologistData: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PSYCHOLOGIST_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_PSYCHOLOGIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        psychologists: action.payload.users,
        totalPsychologists: action.payload.totalUsers,
        error: ""
      };
    case FETCH_PSYCHOLOGIST_EXPORT_DATA:
      return {
        ...state,
        exportPsychologistData: action.payload
      };
    case FETCH_PSYCHOLOGIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        psychologists: [],
        error: action.payload
      };
    case SELECT_PSYCHOLOGIST:
      return {
        ...state,
        selectedPsychologist: action.payload
      };
    default:
      return state;
  }
};

export default reducer;

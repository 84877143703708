export const FETCH_USERS_REQUEST = "FETCH_USERS_REQUEST";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USERS_FAILURE = "FETCH_USERS_FAILURE";

export const SET_IS_SIGNING = "SET_IS_SIGNING";
export const RESET_IS_SIGNING = "RESET_IS_SIGNING";

export const SET_LOGGEDIN_USER = "SET_USER";
export const SET_LOGGEDOUT_USER = "SET_LOGGEDOUT_USER";
export const SET_FORGET_PASSWORD_USER_EMAIL = "SET_FORGET_PASSWORD_USER_EMAIL";
export const RESET_FORGET_PASSWORD_USER_EMAIL = "RESET_FORGET_PASSWORD_USER_EMAIL";

export const FETCH_PATIENTS_REQUEST = "FETCH_PATIENTS_REQUEST";
export const FETCH_PATIENTS_SUCCESS = "FETCH_PATIENTS_SUCCESS";
export const FETCH_PATIENTS_FAILURE = "FETCH_PATIENTS_FAILURE";

export const FETCH_PSYCHOLOGIST_REQUEST = "FETCH_PSYCHOLOGIST_REQUEST";
export const FETCH_PSYCHOLOGIST_SUCCESS = "FETCH_PSYCHOLOGIST_SUCCESS";
export const FETCH_PSYCHOLOGIST_FAILURE = "FETCH_PSYCHOLOGIST_FAILURE";

export const REFRESH_STATE = "REFRESH_STATE";

export const SELECT_PSYCHOLOGIST = "SELECT_PSYCHOLOGIST";

export const SET_FORGET_PASSWORD_USER_OTP = "SET_FORGET_PASSWORD_USER_OTP";
export const RESET_FORGET_PASSWORD_USER_OTP = "RESET_FORGET_PASSWORD_USER_OTP";

import axiosInstance from "./AxiosInstance";

export const getTestListApi = () => axiosInstance.get("api/assessments/list");

export const getAllTestListApi = () =>
  axiosInstance.get("api/assessments/list", {
    params: {
      all: true
    }
  });

export const getTestsListApi = (prms) =>
  axiosInstance.get(`api/assessments`, {
    params: {
      page: prms?.page - 1 || 0,
      limit: prms?.limit || 10,
      search: prms?.search || ""
    }
  });

export const getTestsExportListApi = () => axiosInstance.get(`api/assessments`);

export const postCreateTestApi = (postData) => axiosInstance.post("api/assessments", postData);

export const getActivateTestApi = (id) => axiosInstance.get(`api/assessments/enable/${id}`);

export const getDeactivateTestApi = (id) => axiosInstance.get(`api/assessments/disable/${id}`);

export const getTestByIdApi = (id) => axiosInstance.get(`api/assessments/${id}`);

export const postUpdateTestApi = (id, postData) =>
  axiosInstance.patch(`api/assessments/${id}`, postData);

export const postSubmitScoringTestApi = (id, postData) =>
  axiosInstance.post(`api/assignments/result/${id}`, postData);

export const getTestForScoringByIdApi = (id) => axiosInstance.get(`api/assignments/result/${id}`);

export const getReportDetailByIdApi = (id) => axiosInstance.get(`api/assignments/export/${id}`);

export const getTestStartApi = (id) => axiosInstance.get(`api/assignments/start/${id}`);

export const patchTestResultApi = (id, postData) =>
  axiosInstance.patch(`api/assignments/result/${id}`, postData);

export const getVerifyTestApi = (id) => axiosInstance.get(`api/assignments/scored/${id}`);

export const postSelectAssessmentApi = (postData) =>
  axiosInstance.post("/api/assessments/psychologist-assessment", postData);

export const getAssessmentExportApi = (id) =>
  axiosInstance.get(`api/assignments/assessment-export/${id}`);

/*eslint-disable*/
import React from "react";
import toast from "react-hot-toast";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, NavLink, useParams } from "react-router-dom";
import { Card, Container, Row, Button, CardBody, Form, FormGroup, Col, Input } from "reactstrap";

import useViewport from "hooks/useViewport";

import Header from "components/Headers/Header.jsx";
import FormMenu from "components/FormMenu/FormMenu";
import GoBackButton from "components/GoBackButton/GoBackButton";

import { selectUserToken } from "redux/user/selectors";
import { selectTests } from "redux/test/selectors";
import {
  selectTestQuestionTypes,
  resetTestData,
  fetchTestByIdThunkAction
} from "redux/test/actions";

import { questionTypesOptions } from "Data/Tests";
import "../assets/css/NewTestPage.css";

const NewTestPage = () => {
  let history = useHistory();
  const { width } = useViewport();
  const dispatch = useDispatch();
  const { newTestQuestionTypes, newTestTitle } = useSelector(selectTests);
  const token = useSelector(selectUserToken);
  const { pathname } = useLocation();
  let { id: testId } = useParams();

  const isEditPage = pathname.includes("update");

  const [testQuestionTypes, setTestQuestionTypes] = React.useState([
    {
      id: uuidv4(),
      type: "",
      value: "",
      label: ""
    }
  ]);
  const [testTitle, setTestTitle] = React.useState(newTestTitle);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    if (testId) {
      dispatch(fetchTestByIdThunkAction(testId));
    }
  }, [testId]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (testQuestionTypes.length === 0) {
      toast.error("Please select at least one type");
      return;
    }

    const hasTypeError = testQuestionTypes.every((test) => {
      if ("submit" in test) {
        return true;
      } else {
        return false;
      }
    });

    if (!hasTypeError) {
      toast.error("Please select type");
      return;
    }

    dispatch(selectTestQuestionTypes(testTitle, testQuestionTypes));
    if (isEditPage) {
      history.push(`/admin/assessment/updatequestions/${testId}`);
    } else {
      history.push("/admin/assessment/add/questions");
    }
  };

  const removeTestHandler = (question) => {
    const newQuestionTypes = testQuestionTypes.filter((item) => item.id !== question.id);
    setTestQuestionTypes(newQuestionTypes);
  };

  const resetFormDataHandler = () => {
    dispatch(resetTestData());
  };

  React.useEffect(() => {
    if (newTestQuestionTypes.length) {
      setTestQuestionTypes(newTestQuestionTypes);
    }
    setTestTitle(newTestTitle);
  }, [newTestQuestionTypes]);

  return (
    <React.Fragment>
      <Header />
      <Container className="mt--6" fluid>
        <Row>
          <div
            className="col"
            style={
              width < 768
                ? {}
                : {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                  }
            }
          >
            <Card
              style={width < 850 ? { width: "100%" } : { width: "100%" }}
              className="bg-secondary shadow border-0"
            >
              <CardBody className="px-lg-5 py-lg-5">
                <div className="new_cabinet_page_title">
                  <h2> {isEditPage ? "Update Assessment" : "Create New Assessment"}</h2>
                  <GoBackButton />
                </div>

                <Form role="form" onSubmit={handleSubmit} className="new_cabinet_form">
                  <FormGroup>
                    <Row>
                      <Col>
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-cabinet-title">
                            Name
                          </label>
                          <Input
                            className="form-control-alternative"
                            required
                            onChange={(e) => setTestTitle(e.target.value)}
                            value={testTitle}
                            id="input-cabinet-title"
                            placeholder="Title"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <FormGroup>
                          <div className="form-control-label mb-2" htmlFor="input-email">
                            Questions
                          </div>

                          <Button
                            color="success"
                            type="button"
                            className="px-2 py-1 mb-2"
                            onClick={() => {
                              setTestQuestionTypes((preData) => [
                                ...preData,
                                {
                                  id: uuidv4(),
                                  type: "",
                                  value: "",
                                  label: ""
                                }
                              ]);
                            }}
                          >
                            Add Section
                          </Button>

                          {testQuestionTypes.map((quesType, index) => {
                            return (
                              <Row className="mb-2" key={quesType?.id}>
                                <Col
                                  sm={3}
                                  lg={2}
                                  className="d-flex align-items-center justify-content-start"
                                >
                                  <span>Section {index + 1}</span>
                                </Col>

                                <Col sm={6} xs={8}>
                                  <FormMenu
                                    menuItems={[...questionTypesOptions]}
                                    selected={{
                                      id: quesType?.id,
                                      title: quesType?.label
                                    }}
                                    onSelectHandler={(_, newItem) => {
                                      setTestQuestionTypes((preData) =>
                                        preData.map((item) => {
                                          if (item.id === quesType.id) {
                                            return {
                                              ...JSON.parse(JSON.stringify(newItem)),
                                              id: item.id
                                            };
                                          }
                                          return item;
                                        })
                                      );
                                    }}
                                    placeHolder="Select type"
                                  />
                                </Col>
                                <Col sm={2} xs={4} className="d-flex align-items-center">
                                  {
                                    <Button
                                      onClick={() => {
                                        setTestQuestionTypes((preData) =>
                                          preData.filter((item) => item.id !== quesType.id)
                                        );
                                      }}
                                      type="button"
                                      style={{
                                        background: "transparent",
                                        boxShadow: "none",
                                        border: "none",
                                        transition: "none",
                                        padding: "0"
                                      }}
                                    >
                                      <i
                                        className="fas fa-times remove_test_section_icon"
                                        style={{
                                          padding: "5px 9px",
                                          trasition: "all 0.3s ease-in-out",
                                          borderRadius: "5px"
                                        }}
                                      ></i>
                                    </Button>
                                  }
                                </Col>
                              </Row>
                            );
                          })}
                        </FormGroup>
                      </Col>
                    </Row>
                  </FormGroup>
                  {testQuestionTypes?.length === 0 ? null : (
                    <div className="text-center">
                      <Button color="main-color" type="submit">
                        {isEditPage ? "Update Questions" : "Add Questions"}
                      </Button>
                      <NavLink to="/admin/assessments" replace>
                        <Button
                          onClick={() => resetFormDataHandler()}
                          style={{ backgroundColor: "red", color: "white" }}
                          type="submit"
                        >
                          Cancel
                        </Button>
                      </NavLink>
                    </div>
                  )}
                </Form>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default NewTestPage;

import axiosInstance from "./AxiosInstance";

export const getPsychologistApi = (prms) =>
  axiosInstance.get("api/users/psychologists", {
    params: {
      page: prms?.page - 1 || 0,
      limit: prms.limit || 10,
      search: prms?.search || ""
    }
  });

export const getPsychologistExportDataApi = () => axiosInstance.get("api/users/psychologists");

export const getActivatePsychologistApi = (id) =>
  axiosInstance.get(`api/users/psychologist/activate/${id}`);

export const getDeactivatePsychologistApi = (id) =>
  axiosInstance.get(`api/users/psychologist/de-activate/${id}`);

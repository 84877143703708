export const getSectionDetails = (section) => {
  switch (section?.type) {
    case "mcq":
      return {
        value: "multiple Choice Questions",
        label: "Multiple Choice",
        next: true,
        prev: true,
        submit: false
      };
    case "text":
      return {
        value: "open Ended Questions",
        label: "Open Ended",
        next: true,
        prev: true,
        submit: false
      };
    case "true-false":
      return {
        value: "true/False",
        label: "True/False",
        next: true,
        prev: true,
        submit: false
      };
    case "mixed":
      return {
        value: "Mixed Type Questions",
        label: "Mixed Type",
        next: true,
        prev: true,
        submit: false
      };
    default:
      return null;
  }
};

export const formatSectionNames = (section) => {
  const newData = section
    .map((item, index) => {
      switch (item) {
        case "mcq":
          return "Multiple Choice";
        case "text":
          return "Open Ended";
        case "true-false":
          return "True/False";
        case "mixed":
          return "Mixed Type";
        default:
          return null;
      }
    })
    .join(", ");

  return newData;
};

export const phoneFormat = (input) => {
  if (input?.length === 10) {
    return input?.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  }

  return "Invalid phone number";
};

export const setGenderHelper = (input) => {
  switch (input) {
    case "female":
      return {
        id: 2,
        label: "Female",
        value: input
      };
    case "male":
      return {
        id: 1,
        label: "Male",
        value: input
      };
    case "identifies as other":
      return {
        id: 3,
        label: "Identifies as other",
        value: input
      };

    default:
      return null;
  }
};

import React, { useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Container, Form, Row } from "reactstrap";
import { useHistory, useLocation, useParams } from "react-router-dom";

import { Box, Paper, Step, StepLabel, Stepper, withStyles } from "@material-ui/core";

import { renderText } from "components/GiveTest/common/DisplayComponent";
import { styles } from "components/GiveTest/common/styles";
import Header from "components/Headers/Header";
import Step1 from "components/GiveTest/Steps/step1";
import Step2 from "components/GiveTest/Steps/step2";
import Step3 from "components/GiveTest/Steps/step3";
import Step4 from "components/GiveTest/Steps/step4";

import { selectTests } from "redux/test/selectors";
import { selectUserToken } from "redux/user/selectors";
import { resetTestData } from "redux/test/actions";
import { submitScoringTestThunkAction } from "redux/test/actions";
import { fetchTestForScoringByIdThunkAction } from "redux/test/actions";
import useViewport from "hooks/useViewport";

import "../assets/css/GiveTestPage.css";

function GiveTestPage(props) {
  let history = useHistory();
  const { newTestQuestionTypes, newTestTitle, selectedTest = {} } = useSelector(selectTests);
  const token = useSelector(selectUserToken);
  const { classes } = props;
  const { width } = useViewport();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  let { assessmentId, assignmentId, patientId } = useParams();

  const [testData, setTestData] = useState(newTestQuestionTypes);
  const [resultData, setResultData] = useState([]);
  const [stepCount, setStepCount] = useState(testData[0]);
  const [countIndex, setCountIndex] = useState(0);

  const isEditPage = pathname.includes("update");

  const handleOnChange = () => {};

  const handleNextStep = (subData) => {
    //-------validation at section level
    const subQuestionsData = resultData?.filter(
      (question) => subData?.questions?.findIndex((ques) => ques?.id === question?.id) > -1
    );

    const hasAnswerError = subQuestionsData?.every((question) => {
      if (question?.subjectAnswer && question?.subjectAnswer?.trim()) {
        return true;
      }
      return false;
    });

    if (!hasAnswerError) {
      toast.error("Please answer all questions.");
      return;
    }
    //----------------------------------

    setStepCount(testData[countIndex + 1]);
    setCountIndex(countIndex + 1);
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  const handleBackStep = () => {
    setStepCount(testData[countIndex - 1]);
    setCountIndex(countIndex - 1);
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  React.useEffect(() => {
    if (assignmentId) {
      const isScoring = isEditPage ? "editing" : "submiting";
      dispatch(fetchTestForScoringByIdThunkAction(assignmentId, isScoring));
    }
  }, [assignmentId]);

  React.useEffect(() => {
    let formatedData = [];

    if (selectedTest?.type === "app") {
      formatedData = [...newTestQuestionTypes];
    } else {
      formatedData = [...newTestQuestionTypes];
    }

    setTestData(formatedData);
    setStepCount(formatedData[0]);
    const tempData = formatedData?.map((item) => item.questions);
    setResultData(
      tempData?.flat()?.map((item) => {
        if ("subjectAnswer" in item || {}) {
          return {
            id: item.id,
            subjectAnswer: item.subjectAnswer,
            subjectScore: item?.subjectScore || 0,
            score: item?.score || 0,
            answer: item?.answer || "",
            type: item?.type
          };
        } else {
          return {
            id: item.id,
            subjectAnswer: "",
            subjectScore: item?.subjectScore || 0,
            score: item?.score || 0,
            answer: item?.answer || "",
            type: item?.type
          };
        }
      })
    );
  }, [newTestQuestionTypes, selectedTest]);

  const getStepContent = (step) => {
    switch (step?.type) {
      case "text":
        return (
          <Step1
            state={{}}
            isEditPage={isEditPage}
            stepCount={stepCount}
            resultData={resultData}
            handlePrev={handleBackStep}
            handleNext={handleNextStep}
            handleAnswerChange={handleAnswerChange}
            handleOnclickNextButton={handleOnclickNextButton}
            handleScoreChange={handleScoreChange}
          />
        );
      case "true-false":
        return (
          <Step2
            isEditPage={isEditPage}
            stepCount={stepCount}
            resultData={resultData}
            handleNext={handleNextStep}
            handlePrev={handleBackStep}
            handleAnswerChange={handleAnswerChange}
            handleOnclickNextButton={handleOnclickNextButton}
          />
        );
      case "mcq":
        return (
          <Step3
            isEditPage={isEditPage}
            stepCount={stepCount}
            resultData={resultData}
            handleNext={handleNextStep}
            handlePrev={handleBackStep}
            handleAnswerChange={handleAnswerChange}
            handleOnclickNextButton={handleOnclickNextButton}
          />
        );

      case "mixed":
        return (
          <Step4
            isEditPage={isEditPage}
            stepCount={stepCount}
            resultData={resultData}
            handleNext={handleNextStep}
            handlePrev={handleBackStep}
            handleAnswerChange={handleAnswerChange}
            handleOnclickNextButton={handleOnclickNextButton}
            handleScoreChange={handleScoreChange}
          />
        );
      default:
        return null;
    }
  };

  const onCreate = () => {
    dispatch(resetTestData());
    history.replace(`/admin/subject-details/${patientId}`);
  };

  const onError = (error) => {};

  const handleSubmit = (e) => {
    const showToastMessage = true;
    e.preventDefault();

    const hasAnswerEmpty = resultData?.every((test) => {
      if (test?.subjectAnswer && test?.subjectAnswer?.trim()) {
        return true;
      }
      return false;
    });

    if (!hasAnswerEmpty) {
      toast.error("All questions are mandatory.");
      return;
    }

    let scoredResultData;

    scoredResultData = resultData.map((item) => {
      if (item?.type === "text") {
        return item;
      }
      return {
        ...item,
        subjectScore: item?.answer === item.subjectAnswer ? item?.score : 0
      };
    });

    const reqBody = {
      questions: scoredResultData,
      isCompleted: true
    };

    dispatch(
      submitScoringTestThunkAction(reqBody, assignmentId, onCreate, onError, showToastMessage)
    );
  };

  const handleOnclickNextButton = (subData) => {
    //-------validation at section level
    const subQuestionsData = resultData?.filter(
      (question) => subData?.questions?.findIndex((ques) => ques?.id === question?.id) > -1
    );

    const hasAnswerError = subQuestionsData?.every((question) => {
      if (question?.subjectAnswer && question?.subjectAnswer?.trim()) {
        return true;
      }
      return false;
    });

    if (!hasAnswerError) {
      // toast.error("Please answer all questions.");
      return;
    }
    //----------------------------------

    const showToastMessage = false;
    const reqBody = {
      questions: resultData
      // isCompleted: false
    };

    if (isEditPage) {
      reqBody.isCompleted = true;
    } else {
      reqBody.isCompleted = false;
    }

    dispatch(
      submitScoringTestThunkAction(reqBody, assignmentId, () => {}, onError, showToastMessage)
    );
  };

  const handleAnswerChange = (questionId, answer, item = {}) => {
    // if (!isEditPage) {
    setResultData((prevState) =>
      prevState.map((item) => {
        if (item?.id === questionId) {
          return {
            ...item,
            subjectAnswer: answer
          };
        }
        return item;
      })
    );
    // } else {
    //   if (item?.type === "text") {
    //     setResultData((prevState) =>
    //       prevState.map((item) => {
    //         if (item?.id === questionId) {
    //           return {
    //             ...item,
    //             subjectAnswer: answer
    //           };
    //         }
    //         return item;
    //       })
    //     );
    //   } else {
    //     setResultData((prevState) =>
    //       prevState.map((item) => {
    //         if (item?.id === questionId) {
    //           return {
    //             ...item,
    //             subjectAnswer: answer,
    //             subjectScore: item?.answer === answer ? item?.score : 0
    //           };
    //         }
    //         return item;
    //       })
    //     );
    //   }
    // }
  };

  // console.log(resultData)

  const handleScoreChange = (questionId, score) => {
    setResultData((prevState) =>
      prevState.map((item) => {
        if (item?.id === questionId) {
          return {
            ...item,
            subjectScore: score
          };
        }
        return item;
      })
    );
  };

  return (
    <React.Fragment>
      <Header />
      <Container className="mt--6" fluid>
        <Row>
          <div
            className="col"
            style={
              width < 768
                ? {}
                : {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                  }
            }
          >
            <Card
              style={width < 850 ? { width: "100%" } : { width: "100%" }}
              className="bg-secondary shadow border-0"
            >
              <CardBody className="px-lg-5 py-lg-5">
                <div className="new_cabinet_page_title">
                  <h2>
                    {isEditPage
                      ? "Edit Assessment"
                      : selectedTest?.status === "inprogress"
                      ? "Resume Assessment"
                      : "Start Assessment"}
                  </h2>
                </div>

                <Form role="form" onSubmit={handleSubmit} className={classes.form}>
                  <Paper component={Box} mb={1} pt={2}>
                    <Box>
                      {renderText({
                        type: "h6",
                        color: "primary",
                        label: `${newTestTitle}`,
                        align: "center"
                      })}
                    </Box>
                    <Stepper
                      activeStep={testData.findIndex((test) => test?.id === stepCount?.id)}
                      alternativeLabel
                    >
                      {testData.length > 0 &&
                        testData.map((item) => (
                          <Step
                            key={item?.label + item?.id}
                            sx={{
                              "& .MuiStepLabel-root .Mui-completed": {
                                color: "red" // circle color (COMPLETED)
                              },
                              "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel": {
                                color: "grey.500" // Just text label (COMPLETED)
                              }
                            }}
                          >
                            <StepLabel
                              StepIconProps={{
                                completed: false
                              }}
                            >
                              {item?.label}
                            </StepLabel>
                          </Step>
                        ))}
                    </Stepper>
                  </Paper>
                  {getStepContent(stepCount)}
                </Form>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default withStyles(styles)(GiveTestPage);

import axiosInstance from "./AxiosInstance";

export const getPatientsApi = (prms, id) =>
  axiosInstance.get("api/subjects", {
    params: {
      page: prms?.page - 1 || 0,
      limit: prms.limit || 10,
      search: prms?.search || "",
      psychologistId: id
    }
  });

export const getPatientsExportDataApi = (id) =>
  axiosInstance.get("api/subjects", {
    params: {
      psychologistId: id
    }
  });

export const postCreatePatientApi = (postData) => axiosInstance.post("api/subjects", postData);

export const getPatientByIdApi = (id, order) =>
  axiosInstance.get(`api/subjects/${id}`, {
    params: {
      order
    }
  });

export const postUpdatePatientApi = (id, postData) =>
  axiosInstance.patch(`api/subjects/${id}`, postData);

export const deletePatientApi = (id) => axiosInstance.delete(`api/subjects/${id}`);

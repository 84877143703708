import React from "react";
import { Box, Grid, Paper } from "@material-ui/core";
import { Col, FormGroup, Input, Row } from "reactstrap";

import { styles } from "../common/styles";
import { renderButton, renderText } from "../common/DisplayComponent";

const Step1 = ({
  stepCount,
  isEditPage,
  resultData,
  handleNext,
  handlePrev,
  handleAnswerChange,
  handleOnclickNextButton,
  handleScoreChange
}) => {
  return (
    <Paper style={styles.steps}>
      <Box mt={2} mb={2}>
        {renderText({
          label: "Open Ended",
          type: "h6",
          color: "textPrimary",
          align: "center"
        })}
      </Box>
      <Row>
        <Col sm="12">
          <div>
            <h3>{stepCount?.title}</h3>
          </div>
        </Col>
        <Col sm="12">
          <div>
            <p>{stepCount?.description}</p>
          </div>
        </Col>
      </Row>

      <hr style={{ margin: "0 0 10px 0" }} />
      <Row>
        {stepCount?.questions?.map((item, index) => {
          return (
            <React.Fragment key={item?.id}>
              <Col sm="10">
                <div>
                  <p>
                    <b>{item?.title}</b>
                  </p>
                </div>
              </Col>

              <Col sm={12}>
                <FormGroup>
                  <Input
                    className="form-control-alternative"
                    required
                    onChange={(e) => handleAnswerChange(item?.id, e.target.value, item)}
                    value={resultData.find((question) => question.id === item?.id)?.subjectAnswer}
                    id="input-cabinet-title"
                    placeholder="Type Answer"
                    type="textarea"
                  />
                </FormGroup>
              </Col>

              {isEditPage && (
                <>
                  <Col sm={8}>
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-cabinet-title">
                        Item Score
                      </label>
                      <Input
                        className="form-control-alternative"
                        min={0}
                        max={resultData.find((question) => question.id === item?.id)?.score}
                        onChange={(e) => {
                          if (
                            Number(e.target.value) <=
                            resultData.find((question) => question.id === item?.id)?.score
                          ) {
                            handleScoreChange(item?.id, Number(e.target.value));
                          }
                        }}
                        onWheel={(e) => e.target.blur()}
                        value={
                          resultData.find((question) => question.id === item?.id)?.subjectScore
                        }
                        id="input-cabinet-title"
                        type="number"
                        step="0.01"
                      />
                    </FormGroup>
                  </Col>
                  <Col sm={4}>
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-cabinet-title">
                        Max Score
                      </label>
                      <Input
                        className="form-control-alternative"
                        disabled
                        value={resultData.find((question) => question.id === item?.id)?.score}
                        id="input-cabinet-title"
                        type="number"
                      />
                    </FormGroup>
                  </Col>
                </>
              )}
            </React.Fragment>
          );
        })}
      </Row>

      <Grid container component={Box} justify="flex-end" mt={2} p={2}>
        {stepCount?.prev && (
          <Box ml={2}>
            {renderButton({
              label: "Back",
              color: "default",
              onClick: handlePrev
            })}
          </Box>
        )}
        {stepCount?.next && (
          <Box ml={2}>
            {renderButton({
              label: "Next",
              onClick: () => {
                handleNext(stepCount);
                handleOnclickNextButton(stepCount);
              }
            })}
          </Box>
        )}
        {stepCount?.submit && (
          <Box ml={2}>{renderButton({ label: "Submit", onClick: () => {}, type: "submit" })}</Box>
        )}
      </Grid>
    </Paper>
  );
};

export default Step1;

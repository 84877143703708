import toast from "react-hot-toast";

import {
  postUpdateTestApi,
  getTestForScoringByIdApi,
  postSubmitScoringTestApi,
  getActivateTestApi,
  patchTestResultApi,
  getTestsExportListApi,
  getTestStartApi,
  getDeactivateTestApi,
  getTestByIdApi,
  getTestsListApi,
  postCreateTestApi
} from "services/TestServices";
import {
  ADD_NEW_QUESTION,
  FETCH_TEST_EXPORT_DATA,
  FETCH_TEST_FAILURE,
  FETCH_TEST_LIST_OPTIONS,
  FETCH_TEST_REQUEST,
  FETCH_TEST_SUCCESS,
  RESET_TEST_DATA,
  SELECTED_TEST,
  SET_TEST_QUESTION_TYPES
} from "./types";
import { getSectionDetails } from "utils/getSection";
import { getVerifyTestApi } from "services/TestServices";
import { postSelectAssessmentApi } from "services/TestServices";

export const fetchTestsRequest = () => {
  return {
    type: FETCH_TEST_REQUEST
  };
};

export const fetchTestsSuccess = (users, totalUsers) => {
  return {
    type: FETCH_TEST_SUCCESS,
    payload: { users, totalUsers }
  };
};

export const fetchTestsFailure = (error) => {
  return {
    type: FETCH_TEST_FAILURE,
    payload: error
  };
};

export const selectedTest = (title, sections, test) => {
  return {
    type: SELECTED_TEST,
    payload: {
      title,
      sections,
      test
    }
  };
};

export const selectTestQuestionTypes = (testTitle, typeArray) => {
  let startIndex = 0;
  let endIndex = typeArray.length - 1;

  typeArray = typeArray.map((type, index) => {
    return {
      ...type,
      next: true,
      prev: true,
      submit: false
    };
  });

  typeArray[startIndex].prev = false;
  typeArray[endIndex].next = false;
  typeArray[endIndex].submit = true;

  return {
    type: SET_TEST_QUESTION_TYPES,
    payload: {
      testTitle,
      typeArray
    }
  };
};

export const addNewQuestion = (typeArray) => {
  return {
    type: ADD_NEW_QUESTION,
    payload: typeArray
  };
};

export const resetTestData = () => {
  return {
    type: RESET_TEST_DATA
  };
};

export const fetchTestListOptions = (typeArray) => {
  return {
    type: FETCH_TEST_LIST_OPTIONS,
    payload: typeArray
  };
};

export const fetchTestsThunkAction = (params, onSuccess, onError) => {
  return async (dispatch) => {
    try {
      dispatch(fetchTestsRequest());
      const { data } = await getTestsListApi(params);

      if (data.success !== true) {
        throw new Error(data.message);
      }
      dispatch(fetchTestsSuccess(data.data.data, data.data.total));
      onSuccess();
    } catch (error) {
      dispatch(fetchTestsFailure(error?.response?.data?.message));
      onError(error?.response?.data?.message);
    }
  };
};

const fetchTestsExportData = (data) => {
  return {
    type: FETCH_TEST_EXPORT_DATA,
    payload: data
  };
};

export const fetchTestsExportDataThunkAction = (onSuccess, onError) => {
  return async (dispatch) => {
    try {
      const { data } = await getTestsExportListApi();

      if (data.success !== true) {
        throw new Error(data.message);
      }
      dispatch(fetchTestsExportData(data.data.data));
    } catch (error) {
      // onError(error?.response?.data?.message);
    }
  };
};

export const createTestThunkAction = (postData, onCreate, onError) => {
  return async (dispatch) => {
    try {
      const promise = postCreateTestApi(postData);

      toast.promise(promise, {
        loading: "Loading...",
        success: "Assessment created successfully.",
        error: (error) => `${error?.response?.data?.message || error?.message}`
      });

      const { data } = await promise;

      if (data.success !== true) {
        throw new Error(data?.message);
      }
      onCreate();
    } catch (error) {
      onError(error?.response?.data?.message);
    }
  };
};

export const updateTestThunkAction = (postData, id, onCreate, onError) => {
  return async (dispatch) => {
    try {
      const promise = postUpdateTestApi(id, postData);

      toast.promise(promise, {
        loading: "Loading...",
        success: "Assessment updated successfully.",
        error: (error) => `${error?.response?.data?.message || error?.message}`
      });

      const { data } = await promise;

      if (data.success !== true) {
        throw new Error(data?.message);
      }
      onCreate();
    } catch (error) {
      onError(error?.response?.data?.message);
    }
  };
};

export const activateTestThunkAction = async (id, onSuccess, onError) => {
  return async (dispatch) => {
    try {
      const promise = getActivateTestApi(id);

      toast.promise(promise, {
        loading: "Loading...",
        success: "Status updated successfully.",
        error: (error) => `${error?.response?.data?.message || error?.message}`
      });

      const { data } = await promise;

      if (data.success !== true) {
        throw new Error(data.message);
      }
      onSuccess();
    } catch (error) {
      onError(error?.response?.data?.message);
    }
  };
};

export const deactivateTestThunkAction = async (id, onSuccess, onError) => {
  return async (dispatch) => {
    try {
      const promise = getDeactivateTestApi(id);

      toast.promise(promise, {
        loading: "Loading...",
        success: "Status updated successfully.",
        error: (error) => `${error?.response?.data?.message || error?.message}`
      });

      const { data } = await promise;

      if (data.success !== true) {
        throw new Error(data.message);
      }
      onSuccess();
    } catch (error) {
      onError(error?.response?.data?.message);
    }
  };
};

export const fetchTestByIdThunkAction = (id) => {
  return async (dispatch) => {
    try {
      const { data } = await getTestByIdApi(id);

      if (data.success !== true) {
        throw new Error(data.message);
      }

      let formatedData = data.data.sections.map((section) => {
        const extraData = getSectionDetails(section);
        return {
          ...section,
          ...extraData
        };
      });

      let startIndex = 0;
      let endIndex = formatedData.length - 1;

      formatedData.sort(function (a, b) {
        if (a.id < b.id) return -1;
        if (a.id > b.id) return 1;
        return 0;
      });

      formatedData = formatedData.map((type, index) => {
        return {
          ...type,
          next: true,
          prev: true,
          submit: false
        };
      });

      formatedData[startIndex].prev = false;
      formatedData[endIndex].next = false;
      formatedData[endIndex].submit = true;

      dispatch(selectedTest(data.data.title, formatedData, null));
    } catch (error) {
      console.log(error?.response?.data?.message);
    }
  };
};

export const submitScoringTestThunkAction = (
  postData,
  assignmentId,
  onCreate,
  onError,
  showToast
) => {
  return async (dispatch) => {
    try {
      const promise = patchTestResultApi(assignmentId, postData);

      if (showToast) {
        toast.promise(promise, {
          loading: "Loading...",
          success: "Assessment Submit successfully.",
          error: (error) => `${error?.response?.data?.message || error?.message}`
        });
      }

      const { data } = await promise;

      if (data.success !== true) {
        throw new Error(data?.message);
      }
      onCreate();
    } catch (error) {
      onError(error?.response?.data?.message);
    }
  };
};

export const fetchTestForScoringByIdThunkAction = (id, isScoring) => {
  return async (dispatch) => {
    try {
      const { data } = await getTestForScoringByIdApi(id);

      if (isScoring === "submiting") {
        const startTest = getTestStartApi(id);
      }
      let responseData = [];
      if ("result" in data.data) {
        responseData = data.data.result;
      } else {
        responseData = data.data.assessment;
      }

      if (data.success !== true) {
        throw new Error(data.message);
      }

      let formatedData = responseData.sections.map((section) => {
        const extraData = getSectionDetails(section);
        return {
          ...section,
          ...extraData
        };
      });

      let startIndex = 0;
      let endIndex = formatedData.length - 1;

      formatedData = formatedData.map((type, index) => {
        return {
          ...type,
          next: true,
          prev: true,
          submit: false
        };
      });

      formatedData[startIndex].prev = false;
      formatedData[endIndex].next = false;
      formatedData[endIndex].submit = true;

      dispatch(selectedTest(responseData.title, formatedData, data.data));
    } catch (error) {
      console.log(error?.response?.data?.message);
    }
  };
};

export const submitTestResultThunkAction = (postData, assignmentId, onCreate, onError) => {
  return async (dispatch) => {
    try {
      const promise = patchTestResultApi(assignmentId, postData);

      toast.promise(promise, {
        loading: "Loading...",
        success: "Assessment Score submitted successfully.",
        error: (error) => `${error?.response?.data?.message || error?.message}`
      });

      const { data } = await promise;

      if (data.success !== true) {
        throw new Error(data?.message);
      }
      onCreate();
    } catch (error) {
      onError(error?.response?.data?.message);
    }
  };
};

export const VerifyAssessmentThunkAction = (assignmentId, onCreate, onError) => {
  return async (dispatch) => {
    try {
      const promise = getVerifyTestApi(assignmentId);

      const { data } = await promise;

      if (data.success !== true) {
        throw new Error(data?.message);
      }
      onCreate();
    } catch (error) {
      onError(error?.response?.data?.message);
    }
  };
};

export const updateAssessmentListThunkAction = (postData, onCreate, onError) => {
  return async (dispatch) => {
    try {
      const promise = postSelectAssessmentApi(postData);

      const { data } = await promise;

      if (data.success !== true) {
        throw new Error(data?.message);
      }
      onCreate();
    } catch (error) {
      onError(error?.response?.data?.message);
    }
  };
};

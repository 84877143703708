export const SET_TEST_QUESTION_TYPES = "SET_TEST_QUESTION_TYPES";

export const ADD_NEW_QUESTION = "ADD_NEW_QUESTION";
export const RESET_TEST_DATA = "RESET_TEST_DATA";

export const FETCH_TEST_REQUEST = "FETCH_TEST_REQUEST";
export const FETCH_TEST_SUCCESS = "FETCH_TEST_SUCCESS";
export const FETCH_TEST_FAILURE = "FETCH_TEST_FAILURE";
export const SELECTED_TEST = "SELECTED_TEST";

export const FETCH_TEST_EXPORT_DATA = "FETCH_TEST_EXPORT_DATA";

export const FETCH_TEST_LIST_OPTIONS = "FETCH_TEST_LIST_OPTIONS";

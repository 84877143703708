import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Container, Form, Row } from "reactstrap";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Box, Paper, Step, StepLabel, Stepper, withStyles } from "@material-ui/core";

import useViewport from "hooks/useViewport";

import Header from "components/Headers/Header";
import Step1 from "components/TestScore/Steps/step1";
import Step2 from "components/TestScore/Steps/step2";
import Step3 from "components/TestScore/Steps/step3";
import Step4 from "components/TestScore/Steps/step4";
import { styles } from "components/TestScore/common/styles";
import GoBackButton from "components/GoBackButton/GoBackButton";
import { renderText } from "components/TestScore/common/DisplayComponent";

import { selectTests } from "redux/test/selectors";
import { selectUserToken } from "redux/user/selectors";
import {
  resetTestData,
  submitScoringTestThunkAction,
  fetchTestForScoringByIdThunkAction,
  submitTestResultThunkAction
} from "redux/test/actions";

import "../assets/css/GiveTestPage.css";
import toast from "react-hot-toast";
import { VerifyAssessmentThunkAction } from "redux/test/actions";
import { selectUser } from "redux/user/selectors";

function TestScorePage(props) {
  let history = useHistory();
  const { newTestQuestionTypes, newTestTitle, selectedTest = {} } = useSelector(selectTests);
  const token = useSelector(selectUserToken);
  const { role: userRole } = useSelector(selectUser);
  const { classes } = props;
  const { width } = useViewport();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  let { assessmentId, assignmentId, patientId } = useParams();

  const isEditPage = pathname.includes("update");
  const isViewPage = pathname.includes("view");

  const [testData, setTestData] = useState(newTestQuestionTypes);
  const [resultData, setResultData] = useState([]);
  const [stepCount, setStepCount] = useState(testData[0]);
  const [countIndex, setCountIndex] = useState(0);
  const [submitting, setSubmitting] = useState(false);
  const [isAssessmentCompleted, setIsAssessmentCompleted] = useState(false);
  const handleOnChange = () => {};

  const handleNextStep = (subData) => {
    setStepCount(testData[countIndex + 1]);
    setCountIndex(countIndex + 1);
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  const handleBackStep = () => {
    setStepCount(testData[countIndex - 1]);
    setCountIndex(countIndex - 1);
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  React.useEffect(() => {
    if (assignmentId) {
      dispatch(fetchTestForScoringByIdThunkAction(assignmentId, "computer"));
    }
  }, [assignmentId]);

  React.useEffect(() => {
    let formatedData = [];
    formatedData = [...newTestQuestionTypes];
    setTestData(formatedData);
    setStepCount(formatedData[0]);
    const tempData = formatedData?.map((item) => item.questions);
    setResultData(
      tempData?.flat()?.map((item) => {
        if ("subjectScore" in item) {
          return {
            id: item.id,
            subjectAnswer: item?.subjectAnswer,
            subjectScore: item?.subjectScore || 0,
            score: item?.score || 0
          };
        } else {
          return {
            id: item.id,
            subjectAnswer: "",
            subjectScore: 0,
            score: item?.score || 0
          };
        }
      })
    );

    setIsAssessmentCompleted(
      selectedTest?.status === "completed" && userRole === "psychologist" ? true : false
    );
  }, [newTestQuestionTypes, selectedTest]);

  const getStepContent = (step) => {
    switch (step?.type) {
      case "text":
        return (
          <Step1
            state={{}}
            isAssessmentCompleted={isAssessmentCompleted}
            isViewPage={isViewPage}
            submitting={submitting}
            stepCount={stepCount}
            resultData={resultData}
            handlePrev={handleBackStep}
            handleNext={handleNextStep}
            handleOnclickNextButton={handleOnclickNextButton}
          />
        );
      case "true-false":
        return (
          <Step2
            isAssessmentCompleted={isAssessmentCompleted}
            isViewPage={isViewPage}
            submitting={submitting}
            stepCount={stepCount}
            resultData={resultData}
            handleNext={handleNextStep}
            handlePrev={handleBackStep}
            handleOnclickNextButton={handleOnclickNextButton}
          />
        );
      case "mcq":
        return (
          <Step3
            isAssessmentCompleted={isAssessmentCompleted}
            isViewPage={isViewPage}
            submitting={submitting}
            stepCount={stepCount}
            resultData={resultData}
            handleNext={handleNextStep}
            handlePrev={handleBackStep}
            handleOnclickNextButton={handleOnclickNextButton}
          />
        );
      case "mixed":
        return (
          <Step4
            isAssessmentCompleted={isAssessmentCompleted}
            isViewPage={isViewPage}
            submitting={submitting}
            stepCount={stepCount}
            resultData={resultData}
            handleNext={handleNextStep}
            handlePrev={handleBackStep}
            handleOnclickNextButton={handleOnclickNextButton}
          />
        );
      default:
        return null;
    }
  };

  const onCreate = () => {
    dispatch(resetTestData());
    history.replace(`/admin/subject-details/${patientId}`);
    setSubmitting(false);
  };

  const onError = (error) => {
    setSubmitting(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setSubmitting(true);

    dispatch(VerifyAssessmentThunkAction(assignmentId, onCreate, onError));
  };

  const handleOnclickNextButton = () => {
    const showToastMessage = false;
    const reqBody = {
      questions: resultData
    };

    dispatch(
      submitScoringTestThunkAction(reqBody, assignmentId, () => {}, onError, showToastMessage)
    );
  };

  return (
    <React.Fragment>
      <Header />
      <Container className="mt--6" fluid>
        <Row>
          <div
            className="col"
            style={
              width < 768
                ? {}
                : {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                  }
            }
          >
            <Card
              style={width < 850 ? { width: "100%" } : { width: "100%" }}
              className="bg-secondary shadow border-0"
            >
              <CardBody className="px-lg-5 py-lg-5">
                <div className="new_cabinet_page_title">
                  <h2>
                    {isEditPage ? "Update Score" : isViewPage ? "View Assessment" : "Add Score"}
                  </h2>
                  <GoBackButton />
                </div>

                <Form role="form" onSubmit={handleSubmit} className={classes.form}>
                  <Paper component={Box} mb={1} pt={2}>
                    <Box>
                      {renderText({
                        type: "h6",
                        color: "primary",
                        label: `${newTestTitle}`,
                        align: "center"
                      })}
                    </Box>
                    <Stepper activeStep={stepCount?.label} alternativeLabel>
                      {testData.length > 0 &&
                        testData.map((item) => (
                          <Step key={item?.label + item?.id}>
                            <StepLabel>{item?.label}</StepLabel>
                          </Step>
                        ))}
                    </Stepper>
                  </Paper>
                  {getStepContent(stepCount)}
                </Form>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default withStyles(styles)(TestScorePage);

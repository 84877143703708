import React, { useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { Card, CardBody, Container, Form, Row } from "reactstrap";
import { Box, Paper, Step, StepLabel, Stepper, withStyles } from "@material-ui/core";

import useViewport from "hooks/useViewport";

import Step1 from "components/TestForm/Steps/step1";
import Step2 from "components/TestForm/Steps/step2";
import Step3 from "components/TestForm/Steps/step3";

import Header from "components/Headers/Header";
import { styles } from "components/TestForm/common/styles";
import { renderText } from "components/TestForm/common/DisplayComponent";

import { selectTests } from "redux/test/selectors";
import { addNewQuestion } from "redux/test/actions";
import { createTestThunkAction, resetTestData, updateTestThunkAction } from "redux/test/actions";
import Step4 from "components/TestForm/Steps/step4";

function TestMultiStepForm(props) {
  let history = useHistory();
  const { newTestQuestionTypes, newTestTitle } = useSelector(selectTests);
  const { classes } = props;
  const { width } = useViewport();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  let { id: testId } = useParams();
  const isEditPage = pathname.includes("update");

  const [testData, setTestData] = useState(newTestQuestionTypes);
  const [stepCount, setStepCount] = useState(testData[0]);
  const [countIndex, setCountIndex] = useState(0);
  const [submitting, setSubmitting] = useState(false);
  const handleOnChange = () => {};

  const handleNextStep = (subData) => {
    //-------validation at section level
    const hasTitleError = [subData]?.every((test) => {
      if (test?.title && test?.title?.trim()) {
        return true;
      }
      return false;
    });

    if (!hasTitleError) {
      toast.error("Please add title for the current assessment section.");
      return;
    }

    const hasDescriptionError = [subData]?.every((test) => {
      if (test?.description) {
        if (!test?.description?.trim()) {
          return false;
        }
        return true;
      }
      return true;
    });

    if (!hasDescriptionError) {
      toast.error("Please provide valid test instructions.");
      return;
    }

    const hasQuestionInSectionError = [subData]?.every((test) => {
      if (test?.questions?.length) {
        return true;
      }
      return false;
    });

    if (!hasQuestionInSectionError) {
      toast.error("Please add at least one question in current assessment section.");
      return;
    }

    const hasQuestionError = subData?.questions?.every((test) => {
      if (test?.title && test?.score && test?.title?.trim()) {
        return true;
      }
      return false;
    });

    if (!hasQuestionError) {
      toast.error("Please add question, answer and score for all questions.");
      return;
    }

    const hasOptionError = subData?.questions
      ?.filter((ques) => ques?.type === "mcq")
      ?.every((test) => {
        if (test?.options && test?.options?.every((opt) => opt?.value?.trim() !== "")) {
          return true;
        }
        return false;
      });

    if (!hasOptionError) {
      toast.error("Please add all the options.");
      return;
    }

    if (!["text", "mixed"].includes(subData?.type)) {
      const hasAnswerError = subData?.questions?.every((test) => {
        if (test?.answer && test?.answer?.trim()) {
          return true;
        }
        return false;
      });

      if (!hasAnswerError) {
        toast.error("Please provide correct answer for all the applicable questions.");
        return;
      }
    }

    if (subData?.type === "mixed") {
      const checkMixDataError = subData?.questions?.every((item) => {
        if (item?.type !== "text") {
          if (item?.answer && item?.answer?.trim()) {
            return true;
          }
          return false;
        }
        return true;
      });

      if (!checkMixDataError) {
        toast.error("Please provide correct answer for all the applicable questions.");
        return;
      }
    }
    //------------------------------

    setStepCount(testData[countIndex + 1]);
    setCountIndex(countIndex + 1);
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  const handleBackStep = () => {
    setStepCount(testData[countIndex - 1]);
    setCountIndex(countIndex - 1);
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  React.useEffect(() => {
    window.onload = function () {
      history.replace("/admin/assessments");
    };
  }, []);

  const handleAddInput = (childStepCount, question) => {
    const selectedTestData = testData?.find((item) => item.id === childStepCount?.id);
    let selectedQuestions = [...selectedTestData.questions];
    selectedQuestions.push(question);
    const newTestData = testData?.map((item) => {
      if (item.id === childStepCount?.id) {
        item.questions = selectedQuestions;
      }
      return item;
    });

    dispatch(addNewQuestion(newTestData));
  };

  const handleRemoveInput = (childStepCount, questionId) => {
    const selectedTestData = testData?.find((item) => item.id === childStepCount?.id);
    let selectedQuestions = [...selectedTestData.questions];
    selectedQuestions = selectedQuestions.filter((item) => item.id !== questionId);
    const newTestData = testData?.map((item) => {
      if (item.id === childStepCount?.id) {
        item.questions = selectedQuestions;
      }
      return item;
    });

    dispatch(addNewQuestion(newTestData));
  };

  const handleAddOption = (childStepCount, questionId, option) => {
    const selectedTestData = testData?.find((item) => item.id === childStepCount?.id);
    let selectedQuestion = selectedTestData.questions.find((item) => item.id === questionId);
    let selectedOptions = [...selectedQuestion.options];
    selectedOptions.push(option);
    selectedTestData.questions = selectedTestData.questions.map((item) => {
      if (item.id === questionId) {
        item.options = selectedOptions;
      }
      return item;
    });
    const newTestData = testData?.map((item) => {
      if (item.id === childStepCount.id) {
        item.questions = selectedTestData.questions;
      }
      return item;
    });
    dispatch(addNewQuestion(newTestData));
  };

  const handleRemoveOption = (childStepCount, questionId, optionId, option) => {
    const selectedTestData = testData?.find((item) => item.id === childStepCount?.id);
    let selectedQuestion = selectedTestData.questions.find((item) => item.id === questionId);

    let selectedOptions = [...selectedQuestion.options];
    selectedOptions = selectedOptions.filter((item) => item.id !== optionId);

    if (selectedOptions.length === 1) {
      handleAnswerChange(childStepCount, questionId, "");
    }

    selectedTestData.questions = selectedTestData.questions.map((item) => {
      if (item.id === questionId) {
        item.options = selectedOptions;
        if (item?.answer === option?.value) {
          item.answer = "";
        }
      }
      return item;
    });

    const newTestData = testData?.map((item) => {
      if (item.id === childStepCount?.id) {
        item.questions = selectedTestData.questions;
      }
      return item;
    });

    dispatch(addNewQuestion(newTestData));
  };

  const getStepContent = (step) => {
    switch (step?.type) {
      case "text":
        return (
          <Step1
            submitting={submitting}
            stepCount={stepCount}
            handlePrev={handleBackStep}
            handleNext={handleNextStep}
            handleSubmit={handleSubmit}
            handleQuestionChange={handleQuestionChange}
            handleAnswerChange={handleAnswerChange}
            handleScoreChange={handleScoreChange}
            handleAddInput={handleAddInput}
            handleRemoveInput={handleRemoveInput}
            handleTitleChange={handleTitleChange}
            handleDescriptionChange={handleDescriptionChange}
            onOrderChange={onOrderChange}
            correctAnswerFeedbackChange={handleCorrectFeedbackChange}
            incorrectAnswerFeedbackChange={handleIncorrectFeedbackChange}
          />
        );
      case "true-false":
        return (
          <Step2
            submitting={submitting}
            stepCount={stepCount}
            handleChange={handleOnChange}
            handleNext={handleNextStep}
            handlePrev={handleBackStep}
            handleSubmit={handleSubmit}
            handleQuestionChange={handleQuestionChange}
            handleAnswerChange={handleAnswerChange}
            handleScoreChange={handleScoreChange}
            handleAddInput={handleAddInput}
            handleRemoveInput={handleRemoveInput}
            handleTitleChange={handleTitleChange}
            handleDescriptionChange={handleDescriptionChange}
            onOrderChange={onOrderChange}
            correctAnswerFeedbackChange={handleCorrectFeedbackChange}
            incorrectAnswerFeedbackChange={handleIncorrectFeedbackChange}
          />
        );
      case "mcq":
        return (
          <Step3
            submitting={submitting}
            stepCount={stepCount}
            handleAddOption={handleAddOption}
            handleRemoveOption={handleRemoveOption}
            handleChange={handleOnChange}
            handleNext={handleNextStep}
            handlePrev={handleBackStep}
            handleSubmit={handleSubmit}
            handleQuestionChange={handleQuestionChange}
            handleAnswerChange={handleAnswerChange}
            handleScoreChange={handleScoreChange}
            handleOptionChange={handleOptionChange}
            handleAddInput={handleAddInput}
            handleRemoveInput={handleRemoveInput}
            handleTitleChange={handleTitleChange}
            handleDescriptionChange={handleDescriptionChange}
            isEditPage={isEditPage}
            onOrderChange={onOrderChange}
            correctAnswerFeedbackChange={handleCorrectFeedbackChange}
            incorrectAnswerFeedbackChange={handleIncorrectFeedbackChange}
          />
        );
      case "mixed":
        return (
          <Step4
            submitting={submitting}
            stepCount={stepCount}
            handleAddOption={handleAddOption}
            handleRemoveOption={handleRemoveOption}
            handleChange={handleOnChange}
            handleNext={handleNextStep}
            handlePrev={handleBackStep}
            handleSubmit={handleSubmit}
            handleQuestionChange={handleQuestionChange}
            handleAnswerChange={handleAnswerChange}
            handleScoreChange={handleScoreChange}
            handleOptionChange={handleOptionChange}
            handleAddInput={handleAddInput}
            handleRemoveInput={handleRemoveInput}
            handleTitleChange={handleTitleChange}
            handleDescriptionChange={handleDescriptionChange}
            isEditPage={isEditPage}
            onOrderChange={onOrderChange}
            correctAnswerFeedbackChange={handleCorrectFeedbackChange}
            incorrectAnswerFeedbackChange={handleIncorrectFeedbackChange}
          />
        );
      default:
        return null;
    }
  };

  const onCreate = () => {
    dispatch(resetTestData());
    history.replace("/admin/assessments");
    setSubmitting(false);
  };

  const onError = (error) => {
    setSubmitting(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setSubmitting(true);

    const hasTitleError = testData?.every((test) => {
      if (test?.title && test?.title?.trim()) {
        return true;
      }
      return false;
    });

    if (!hasTitleError) {
      toast.error("Please add title for all test sections.");
      setSubmitting(false);
      return;
    }

    const hasDescriptionError = testData?.every((test) => {
      if (test?.description) {
        if (!test?.description?.trim()) {
          return false;
        }
        return true;
      }
      return true;
    });

    if (!hasDescriptionError) {
      toast.error("Please provide valid test instructions.");
      setSubmitting(false);
      return;
    }

    const hasQuestionInSectionError = testData?.every((test) => {
      if (test?.questions?.length) {
        return true;
      }
      return false;
    });

    if (!hasQuestionInSectionError) {
      toast.error("Please add at least one question in each section.");
      setSubmitting(false);
      return;
    }

    const tempData = testData?.map((item) => item.questions)?.flat();

    const hasQuestionError = tempData?.every((test) => {
      if (test?.title && test?.score && test?.title?.trim()) {
        return true;
      }
      return false;
    });

    if (!hasQuestionError) {
      toast.error("Please add question, answer and score for all questions.");
      setSubmitting(false);
      return;
    }

    const hasOptionError = testData
      ?.filter((item) => ["mcq", "mixed"].includes(item.type))
      ?.map((item) => item.questions)
      ?.flat()
      ?.filter((item) => item?.type === "mcq")
      ?.every((test) => {
        if (test?.options && test?.options?.every((opt) => opt?.value?.trim() !== "")) {
          return true;
        }
        return false;
      });

    if (!hasOptionError) {
      toast.error("Please add all the options");
      setSubmitting(false);
      return;
    }

    const hasAnswerError = testData
      ?.filter((item) => !["text", "mixed"].includes(item.type))
      ?.map((item) => item.questions)
      ?.flat()
      ?.every((test) => {
        if (test?.answer && test?.answer?.trim()) {
          return true;
        }
        return false;
      });

    if (!hasAnswerError) {
      toast.error("Please provide correct answer for all the applicable questions.");
      setSubmitting(false);
      return;
    }

    const checkMixDataError = testData
      ?.filter((item) => item.type === "mixed")
      ?.map((item) => item?.questions)
      ?.flat()
      ?.every((item) => {
        if (item?.type !== "text") {
          if (item?.answer && item?.answer?.trim()) {
            return true;
          }
          return false;
        }
        return true;
      });

    if (!checkMixDataError) {
      toast.error("Please provide correct answer for all the applicable questions.");
      setSubmitting(false);
      return;
    }

    const newTestData = testData?.map((data) => {
      if (["text"].includes(data.type)) {
        return data;
      }

      if (data.type === "mixed") {
        return {
          ...data,
          questions: data.questions?.map((question) => {
            if (question.type === "text") {
              return question;
            }
            return {
              ...question,
              options: [
                ...question.options.filter((item) => item?.id !== "No Response"),
                {
                  id: "No Response",
                  value: "No Response",
                  label: "No Response"
                }
              ]
            };
          })
        };
      }

      return {
        ...data,
        questions: data.questions.map((question) => {
          return {
            ...question,
            options: [
              ...question.options.filter((item) => item?.id !== "No Response"),
              {
                id: "No Response",
                value: "No Response",
                label: "No Response"
              }
            ]
          };
        })
      };
    });

    const updatedTestData = testData?.map((data) => {
      if (data.type === "text" || data.type === "true-false") {
        return data;
      }

      if (data.type === "mixed") {
        return {
          ...data,
          questions: data.questions?.map((question) => {
            if (["text", "true-false"].includes(question.type)) {
              return question;
            }

            return {
              ...question,
              options: [
                ...question.options.filter((item) => item?.id !== "No Response"),
                {
                  id: "No Response",
                  value: "No Response",
                  label: "No Response"
                }
              ]
            };
          })
        };
      }
      return {
        ...data,
        questions: data.questions.map((question) => {
          return {
            ...question,
            options: [
              ...question.options.filter((item) => item?.id !== "No Response"),
              {
                id: "No Response",
                value: "No Response",
                label: "No Response"
              }
            ]
          };
        })
      };
    });

    const reqBody = {
      title: newTestTitle,
      sections: isEditPage ? updatedTestData : newTestData
    };

    if (isEditPage) {
      dispatch(updateTestThunkAction(reqBody, testId, onCreate, onError));
    } else {
      dispatch(createTestThunkAction(reqBody, onCreate, onError));
    }
  };

  const onOrderChange = (childStepCount, questions) => {
    const newTestData = testData?.map((item) => {
      if (item.id === childStepCount.id) {
        item.questions = questions;
      }
      return item;
    });

    dispatch(addNewQuestion(newTestData));
  };

  const handleQuestionChange = (childStepCount, questionId, question) => {
    let subTestData = testData?.find((item) => item.id === childStepCount?.id);
    let subQuestion = subTestData.questions.find((item) => item.id === questionId);
    subQuestion.title = question;
    subTestData.questions = subTestData.questions.map((item) => {
      if (item.id === questionId) {
        return subQuestion;
      }

      return item;
    });
    const newTestData = testData?.map((item) => {
      if (item.id === childStepCount?.id) {
        return subTestData;
      }
      return item;
    });

    dispatch(addNewQuestion(newTestData));
  };

  const handleAnswerChange = (childStepCount, questionId, answer) => {
    let subTestData = testData?.find((item) => item.id === childStepCount?.id);
    let subQuestion = subTestData.questions.find((item) => item.id === questionId);
    subQuestion.answer = answer;
    subTestData.questions = subTestData.questions.map((item) => {
      if (item.id === questionId) {
        return subQuestion;
      }

      return item;
    });
    const newTestData = testData?.map((item) => {
      if (item.id === childStepCount?.id) {
        return subTestData;
      }
      return item;
    });

    dispatch(addNewQuestion(newTestData));
  };

  const handleScoreChange = (childStepCount, questionId, score) => {
    let subTestData = testData?.find((item) => item.id === childStepCount?.id);
    let subQuestion = subTestData.questions.find((item) => item.id === questionId);
    subQuestion.score = Number(score);
    subTestData.questions = subTestData.questions.map((item) => {
      if (item.id === questionId) {
        return subQuestion;
      }

      return item;
    });
    const newTestData = testData?.map((item) => {
      if (item.id === childStepCount?.id) {
        return subTestData;
      }
      return item;
    });

    dispatch(addNewQuestion(newTestData));
  };

  const handleOptionChange = (childStepCount, questionId, optionId, optionValue) => {
    let subTestData = testData?.find((item) => item.id === childStepCount?.id);
    let subQuestion = subTestData.questions.find((item) => item.id === questionId);
    let subOptions = [...subQuestion.options];

    subOptions = subOptions.map((item) => {
      if (item.id === optionId) {
        return {
          ...item,
          value: optionValue,
          label: optionValue
        };
      }
      return item;
    });

    subQuestion.options = subOptions;

    subTestData.questions = subTestData.questions.map((item) => {
      if (item.id === questionId) {
        return subQuestion;
      }
      return item;
    });
    const newTestData = testData?.map((item) => {
      if (item.id === childStepCount?.id) {
        return subTestData;
      }
      return item;
    });

    dispatch(addNewQuestion(newTestData));
  };

  const handleTitleChange = (childStepCount, title) => {
    let subTestData = testData?.find((item) => item.id === childStepCount?.id);
    subTestData.title = title;
    const newTestData = testData?.map((item) => {
      if (item.id === childStepCount.id) {
        return subTestData;
      }
      return item;
    });

    dispatch(addNewQuestion(newTestData));
  };

  const handleDescriptionChange = (childStepCount, description) => {
    let subTestData = testData?.find((item) => item.id === childStepCount?.id);
    subTestData.description = description;
    const newTestData = testData?.map((item) => {
      if (item.id === childStepCount?.id) {
        return subTestData;
      }
      return item;
    });

    dispatch(addNewQuestion(newTestData));
  };

  // theses are section level answer feedback handlers

  // const handleCorrectFeedbackChange = (childStepCount, correctFeedback) => {
  //   let subTestData = testData?.find((item) => item.id === childStepCount?.id);
  //   subTestData.correctAnswerFeedback = correctFeedback;
  //   const newTestData = testData?.map((item) => {
  //     if (item.id === childStepCount?.id) {
  //       return subTestData;
  //     }
  //     return item;
  //   });
  //   dispatch(addNewQuestion(newTestData));
  // };

  // const handleIncorrectFeedbackChange = (childStepCount, incorrectFeedback) => {
  //   let subTestData = testData?.find((item) => item.id === childStepCount?.id);
  //   subTestData.incorrectAnswerFeedback = incorrectFeedback;
  //   const newTestData = testData?.map((item) => {
  //     if (item.id === childStepCount?.id) {
  //       return subTestData;
  //     }
  //     return item;
  //   });
  //   dispatch(addNewQuestion(newTestData));
  // };

  const handleCorrectFeedbackChange = (childStepCount, questionId, correctFeedback) => {
    let subTestData = testData?.find((item) => item.id === childStepCount?.id);
    let subQuestion = subTestData.questions.find((item) => item.id === questionId);
    subQuestion.correctAnswerFeedback = correctFeedback;

    subTestData.questions = subTestData.questions.map((item) => {
      if (item.id === questionId) {
        return subQuestion;
      }

      return item;
    });

    const newTestData = testData?.map((item) => {
      if (item.id === childStepCount?.id) {
        return subTestData;
      }
      return item;
    });

    dispatch(addNewQuestion(newTestData));
  };

  const handleIncorrectFeedbackChange = (childStepCount, questionId, incorrectFeedback) => {
    let subTestData = testData?.find((item) => item.id === childStepCount?.id);
    let subQuestion = subTestData.questions.find((item) => item.id === questionId);
    subQuestion.incorrectAnswerFeedback = incorrectFeedback;

    subTestData.questions = subTestData.questions.map((item) => {
      if (item.id === questionId) {
        return subQuestion;
      }

      return item;
    });

    const newTestData = testData?.map((item) => {
      if (item.id === childStepCount?.id) {
        return subTestData;
      }
      return item;
    });

    dispatch(addNewQuestion(newTestData));
  };

  return (
    <React.Fragment>
      <Header />
      <Container className="mt--6" fluid>
        <Row>
          <div
            className="col"
            style={
              width < 768
                ? {}
                : {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                  }
            }
          >
            <Card
              style={width < 850 ? { width: "100%" } : { width: "100%" }}
              className="bg-secondary shadow border-0"
            >
              <CardBody className="px-lg-5 py-lg-5">
                <div className="new_cabinet_page_title">
                  <h2> {isEditPage ? "Update Assessment" : "Create New Assessment"}</h2>
                </div>

                <Form role="form" onSubmit={handleSubmit} className={classes.form}>
                  <Paper
                    component={Box}
                    mb={1}
                    pt={2}
                    style={{
                      overflowX: "scroll"
                    }}
                  >
                    <Box>
                      {renderText({
                        type: "h6",
                        color: "primary",
                        label: `${newTestTitle}`,
                        align: "center"
                      })}
                    </Box>
                    <Stepper activeStep={stepCount?.id + "step"} alternativeLabel>
                      {testData.length > 0 &&
                        testData.map((item) => (
                          <Step key={item?.label + item?.id}>
                            <StepLabel>{item?.label}</StepLabel>
                          </Step>
                        ))}
                    </Stepper>
                  </Paper>
                  {getStepContent(stepCount)}
                </Form>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default withStyles(styles)(TestMultiStepForm);

import React from "react";

function Switcher({ onChangeHandler, selectedItem }) {
  const statusLabel = selectedItem?.status ? "Inactive" : "Active";
  return (
    <>
      <label className="custom-toggle">
        <input
          type="checkbox"
          checked={selectedItem?.status}
          onClick={() => onChangeHandler(selectedItem, statusLabel)}
        />
        <span className="custom-toggle-slider rounded-circle" />
      </label>
    </>
  );
}

export default Switcher;

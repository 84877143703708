import React from "react";
import { DropdownToggle, UncontrolledDropdown } from "reactstrap";

function Dropdown(props) {
  return (
    <React.Fragment>
      <UncontrolledDropdown
        style={{
          position: "static"
        }}
      >
        <DropdownToggle
          className="btn-icon-only text-light"
          href="#pablo"
          role="button"
          size="sm"
          color=""
          onClick={(e) => e.preventDefault()}
        >
          <i className="fas fa-ellipsis-v" />
        </DropdownToggle>
        {props.children}
      </UncontrolledDropdown>
    </React.Fragment>
  );
}

export default Dropdown;

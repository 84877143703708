import moment from "moment";
import { logoBase64 } from "components/Images/logo";

function* chunks(arr, n) {
  for (let i = 0; i < arr.length; i += n) {
    yield arr.slice(i, i + n);
  }
}

export const pdfData = (pdfdata, patientData) => {
  const age = () => {
    return moment().diff(moment(`${patientData?.dob}`, "MMDDYYYY"), "years");
  };
  const totalcorrect = () => {
    return ((pdfdata.score / pdfdata.assessment.score) * 100).toFixed(0);
  };

  const Title = pdfdata?.result?.sections.reduce(
    (prevValue, currentValue) => prevValue + `${currentValue.title} + `,
    " "
  );
  const TotalItem = pdfdata?.result?.sections.reduce(
    (prevValue, currentValue) => prevValue + currentValue.numberOfQuestions,
    0
  );

  const TotalNorespons = pdfdata?.result?.sections.reduce(
    (prevValue, currentValue) => prevValue + currentValue.numberOfQuestionsWithNoResponse,
    0
  );

  const totalrespons = () => {
    return (((TotalItem - TotalNorespons) / TotalItem) * 100).toFixed(0);
  };

  const TimeRecord = pdfdata?.result?.sections.map((item) => {
    if (item.timings.length > 0) {
      return item?.timings?.map((data) => {
        return [
          `${item.title}`,
          `${moment(data.start).format("h:mm:ss a")}`,
          `${moment(data.end).format("h:mm:ss a")}`,
          `${(data.timing / 60).toFixed(1)} `
        ];
      });
    } else {
      return [["", "", "", ""]];
    }
  });

  const Totaltime = pdfdata?.result?.sections.map((item) => {
    if (item.timings.length > 0) {
      return item?.timings?.reduce(
        (prevValue, currentValue) => prevValue + currentValue.timing / 60,
        0
      );
    } else {
      return 0;
    }
  });
  const Sumtime = Totaltime.reduce(
    (prevValue, currentValue) => prevValue + currentValue,
    0
  ).toFixed(1);

  const TotalTime = () => {
    if (TimeRecord.length > 1) {
      return ["Time", "", "", `${Sumtime}`];
    } else {
      return ["", "", "", ""];
    }
  };

  const TotalResultScore = () => {
    if (pdfdata?.result?.sections.length > 1) {
      return [
        "Totals",
        " ",
        pdfdata?.result?.sections.reduce(
          (prevValue, currentValue) => prevValue + currentValue.numberOfQuestionsWithNoResponse,
          0
        ),
        `${totalrespons()}%`,
        `${totalcorrect()}%`,
        pdfdata?.result?.sections.reduce(
          (prevValue, currentValue) => prevValue + currentValue.totalScore,
          0
        )
      ];
    } else {
      return ["", "", "", "", "", ""];
    }
  };

  const TableRes = pdfdata?.result?.sections.map((items) => {
    var chunksize = 10;

    var chunkes = [];

    if (items?.type === "mcq") {
      chunksize = 16;
    } else if (items?.type === "true-false") {
      chunksize = 16;
    } else {
      chunksize = 1;
    }

    items?.questions.forEach((item) => {
      if (!chunkes.length || chunkes[chunkes.length - 1].length == chunksize) chunkes.push([]);
      chunkes[chunkes.length - 1].push(item);
    });
    chunkes.unshift([
      {
        subjectAnswer: `${items.title}`,
        serialNumber: "",
        Type: `${
          items.type === "true-false" ? "Legend : ( A = True, B = False, C = No response)" : ""
        }`
      }
    ]);
    return chunkes.map((data, index) => {
      return {
        layout: "noBorders",
        margin: [15, 10, 0, 0],
        widths: ["*", "auto", 100, "*"],
        table: {
          headerRows: 1,
          body: [
            data?.map((datas) => {
              if (index === 0) {
                return [
                  { text: ` ${datas?.subjectAnswer}`, bold: true },
                  {
                    text: ` ${datas?.Type}`,
                    fontSize: 8,
                    alignment: "center",
                    absolutePosition: {
                      x: 265,
                      y: 5
                    }
                  }
                ];
              } else {
                return [
                  {
                    text: `${
                      datas.serialNumber < 10 ? "0" + datas.serialNumber : datas.serialNumber
                    } :  ${datas?.subjectAnwserIndex || datas?.subjectAnswer}`,
                    fontSize: 8,
                    padding: 0,
                    margin: 0
                  }
                ];
              }
            })
          ]
        }
      };
    });
  });

  return {
    content: [
      {
        text: "111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111",
        color: "#375582",
        background: "#375582"
      },
      // Header
      {
        columns: [
          {
            image: logoBase64,
            width: 150
          },

          [
            {
              text: `${pdfdata?.assessment?.title}\n\n Test Record`,
              style: "header",
              alignment: "center",
              margin: [0, 60],
              bold: "true"
            }
          ]
        ]
      },
      {
        text: "1111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111",
        color: "#375582",
        background: "#375582"
      },
      // Billing Headers
      {
        layout: "noBorders",
        margin: [10, 10, 5, 5],
        // width:["auto",50,"auto","auto"],
        table: {
          body: [
            [
              { text: "Examinee:", alignment: "right", bold: true },
              { text: `${patientData?.name}`, margin: [0, 0, 120, 0] },
              { text: " Test Admin:", alignment: "right", bold: true },
              { text: `${pdfdata?.assessment?.title}` }
            ],
            [
              { text: "Birthdate:", alignment: "right", bold: true },
              { text: `${patientData?.dob}`, margin: [0, 0, 120, 0] },
              { text: "Date:", alignment: "right", bold: true },
              { text: `${moment(pdfdata?.examinationTime).format("MM/DD/YYYY")}` }
            ],
            [
              { text: "Age:", alignment: "right", bold: true },
              { text: `${age()}`, margin: [0, 0, 120, 0] },
              { text: "Location:", alignment: "right", bold: true },
              { text: `${pdfdata?.examinationPlace}` }
            ],
            [
              { text: "Gender:", alignment: "right", bold: true },
              {
                text: `${
                  patientData?.gender?.substring(0, 1)?.toUpperCase() +
                  patientData?.gender?.substring(1)
                }`,
                margin: [0, 0, 120, 0]
              },
              { text: "Examiner:", alignment: "right", bold: true },
              { text: `${pdfdata?.psychologist?.name}` }
            ],
            [
              { text: "Language(s):", alignment: "right", bold: true },
              {
                text: `${pdfdata?.assessment?.languageId === "en" ? "English" : "Spanish"}`,
                margin: [0, 0, 120, 0]
              },
              { text: " Scoring Status:", alignment: "right", bold: true },
              { text: `${pdfdata?.psychologist?.isVerified ? "Verified" : "Not verified"}` }
            ]
          ]
        }
      },
      {
        text: "_________________________________________________________________________________________________________________",
        bold: true
      },

      "\n\n",

      {
        text: "Results Summary",
        style: "header"
      },
      {
        columns: [
          { width: "*", text: "" },
          {
            layout: "headerLineOnly",
            width: "auto",
            table: {
              headerRows: 1,
              body: [
                ["", "#Items", "NR", "%Resp", "%Correct", "Total Scores"],
                ...pdfdata?.result?.sections.map((item) => {
                  return [
                    `${item.title}`,
                    `${item.numberOfQuestions}`,
                    `${item.numberOfQuestionsWithNoResponse}`,
                    `${item.percentageOfQuestionsWithResponse.toFixed(0)}%`,
                    `${item.percentageOfQuestionsWithCorrectAnswer.toFixed(0)}%`,
                    `${item.totalScore}`
                  ];
                }),
                TotalResultScore()
              ]
            }
          },
          { width: "*", text: "" }
        ]
      },

      {
        text: "________________________________________________________________________________________________________________",
        bold: true
      },
      "\n\n",

      {
        text: "Testing Record",
        style: "header"
      },
      {
        columns: [
          { width: "*", text: "" },
          {
            layout: "headerLineOnly",
            width: "auto",
            table: {
              headerRows: 1,
              body: [["", "Start", "End", "Total"], ...TimeRecord.flat(), TotalTime()]
            }
          },
          { width: "*", text: "" }
        ]
      },

      {
        text: "_________________________________________________________________________________________________________________",
        bold: true
      },
      "\n\n",
      {
        text: "Item Responses",
        style: "header"
      },
      "\n\n",
      TableRes.map((item) => {
        return item;
      })
    ],
    styles: {
      // Document Header
      header: {
        bold: true,
        alignment: "center"
      }
    },
    defaultStyle: {
      // columnGap: 20,
      fontSize: 10
    }
  };
};

import React from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";

import { adminRoutes, psychologistRoutes } from "../routes";
import Authorization from "hoc/Authorization";

import Error from "pages/Error";
import ResetPasswordAdmin from "pages/ResetPasswordAdmin";
import PsychologistsPage from "pages/PsychologistsPage";
import PatientsPage from "pages/PatientsPage";

import { selectUser } from "../redux/user/selectors";

const Admin = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();
  const { role } = useSelector(selectUser);
  const isAdminUser = role === "admin" ? adminRoutes : psychologistRoutes;
  const authenticateRoute = Authorization();

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
    document.body.classList.add("white");
    document.body.style.backgroundColor = "#fff";
    return () => {
      document.body.classList.remove("white");
    };
  }, [location]);

  const getRoutes = (adminRoutes) => {
    return adminRoutes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
      } else {
        return null;
      }
    });
  };

  const getBrandText = (path) => {
    for (let i = 0; i < isAdminUser.length; i++) {
      if (props.location.pathname.indexOf(isAdminUser[i].layout + isAdminUser[i].path) !== -1) {
        return isAdminUser[i].name;
      }
    }
    return "Brand";
  };

  return (
    <>
      <Sidebar
        {...props}
        routes={isAdminUser.filter((item) => !item.isRouteOnly)}
        logo={{
          innerLink: "/admin/list",
          // eslint-disable-next-line no-undef
          imgSrc: require("../assets/img/brand/PSYCHTECH.png").default,
          imgAlt: "..."
        }}
      />
      <div className="main-content" ref={mainContent}>
        <AdminNavbar {...props} brandText={getBrandText(props.location.pathname)} />

        <Switch>
          {getRoutes(isAdminUser)}

          <Route
            path="/admin/resetpassword"
            exact
            component={authenticateRoute(ResetPasswordAdmin)}
          />
          {role === "admin" ? (
            <>
              <Route
                path="/admin/psychologists"
                exact
                component={authenticateRoute(PsychologistsPage)}
              />
              <Route path="/admin/subjects/:id" exact component={authenticateRoute(PatientsPage)} />
              <Route path="/admin/list" exact>
                <Redirect to="/admin/psychologists" />
              </Route>
              <Route>
                <Error
                  error="404 Page not found"
                  message="We can not seem to find the page you are looking for."
                />
              </Route>
            </>
          ) : (
            <>
              <Route path="/admin/list" exact>
                <Redirect to="/admin/subjects" />
              </Route>
              <Route>
                <Error
                  error="404 Page not found"
                  message="We can not seem to find the page you are looking for."
                />
              </Route>
            </>
          )}
        </Switch>
      </div>
    </>
  );
};

export default Admin;

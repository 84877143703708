/*eslint-disable*/
import React from "react";
import { debounce } from "lodash";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardHeader, CardFooter, Media, Table, Container, Row } from "reactstrap";

import useViewport from "hooks/useViewport";

import Header from "components/Headers/Header.jsx";
import TooltipIcon from "components/TooltipIcon/TooltipIcon";
import Search from "components/Search/Search";
import Loader from "components/Loader/Loader";
import ExportButton from "components/ExportButton/ExportButton";
import NewPagination from "components/NewPagination/NewPagination";

import { selectPsychologists } from "redux/psychologists/selectors";
import { selectUserToken } from "redux/user/selectors";
import {
  selectPsychologist,
  fetchPsychologistThunkAction,
  deactivatePsychologistThunkAction,
  activatePsychologistThunkAction,
  fetchPsychologistsExportDataThunkAction
} from "redux/psychologists/actions";

import { phoneFormat } from "utils/getSection";
import "../assets/css/PsychologistsPage.css";
import SelectAssessmentModal from "components/Modals/SelectAssessmentModal";
import { getTestListApi } from "services/TestServices";
import { fetchTestListOptions } from "redux/test/actions";
import { getAllTestListApi } from "services/TestServices";
import { updateAssessmentListThunkAction } from "redux/test/actions";

const PsychologistsPage = () => {
  const [psychologistData, setPsychologistData] = React.useState([]);
  const [error, setError] = React.useState(null);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [postsPerPage] = React.useState(10);
  const [search, setsSearch] = React.useState("");
  const [middleSearch, setMiddleSearch] = React.useState("");
  const [exportedData, setExportedData] = React.useState([]);
  const [openCategoryModal, setOpenCategoryModal] = React.useState(false);
  const [selectedPsycho, setSelectedPsycho] = React.useState(null);
  const [testSelectList, setTestSelectList] = React.useState([]);

  const toggleCategoryModal = () => {
    if (!openCategoryModal) {
      setSelectedPsycho(null);
    }
    setOpenCategoryModal((open) => !open);
  };

  let history = useHistory();
  const { width } = useViewport();
  const dispatch = useDispatch();
  const { psychologists, isLoading, totalPsychologists, exportPsychologistData } =
    useSelector(selectPsychologists);
  const token = useSelector(selectUserToken);

  const onSuccess = () => {
    setError(null);
  };

  const onError = (error) => {
    setError(error);
  };

  const onSearchHandler = (keyword) => {
    setCurrentPage(1);
    setsSearch(keyword);
  };

  const onUpdateError = (error) => {
    toast.error(error);
  };

  const onUpdateSuccess = () => {
    dispatch(
      fetchPsychologistThunkAction(
        {
          search: search || "",
          limit: postsPerPage,
          page: currentPage
        },
        onSuccess,
        onError
      )
    );
  };

  const psychologistStatusChangeHandler = async (psycho, statusLabel) => {
    if (!statusLabel) {
      await dispatch(
        await deactivatePsychologistThunkAction(psycho?.id, onUpdateSuccess, onUpdateError)
      );
    } else {
      await dispatch(
        await activatePsychologistThunkAction(psycho?.id, onUpdateSuccess, onUpdateError)
      );
    }
  };

  const navigateToPatientsListPage = (id) => {
    const selectedPsychologist = psychologists.find((psych) => psych.id === id);
    dispatch(selectPsychologist(selectedPsychologist));
    history.push(`/admin/subjects/${id}`);
  };

  React.useEffect(() => {
    dispatch(
      fetchPsychologistThunkAction(
        {
          search,
          limit: postsPerPage,
          page: currentPage
        },
        onSuccess,
        onError
      )
    );
  }, [currentPage, search]);

  React.useEffect(() => {
    setPsychologistData(psychologists);
  }, [psychologists, psychologists?.length]);

  const debouncedSave = React.useCallback(
    debounce((searchText) => {
      setsSearch(searchText);
      setCurrentPage(1);
    }, 700),
    [] // will be created only once initially
  );

  React.useEffect(() => {
    debouncedSave(middleSearch);
  }, [middleSearch]);

  React.useEffect(() => {
    dispatch(fetchPsychologistsExportDataThunkAction());
  }, []);

  React.useEffect(() => {
    const formatedData = exportPsychologistData.map((item) => ({
      name: item.name,
      email: item.email,
      mobile: item.mobile,
      role: item.role,
      varified: item.status ? "Verified" : "Not Verified",
      status: item.deletedAt ? "Deleted" : item.isVerified ? "Active" : "Inactive"
    }));

    const csvData = formatedData.reduce((acc, item) => {
      const { name, email, mobile, varified, status } = item;
      acc.push([name, email, mobile, varified, status].join(","));
      return acc;
    }, []);

    setExportedData(csvData);
  }, [exportPsychologistData]);

  const onSubmitHandler = (id, list) => {
    dispatch(
      updateAssessmentListThunkAction(
        {
          psychologistId: id,
          assessmentIds: list
        },
        () => {
          dispatch(
            fetchPsychologistThunkAction(
              {
                search,
                limit: postsPerPage,
                page: currentPage
              },
              onSuccess,
              onError
            )
          );
        },
        () => {}
      )
    );

    setPsychologistData((preData) =>
      preData.map((item) => {
        if (item?.id === id) {
          console.log("match");
          return {
            ...item,
            connectedAssessmentIds: list
          };
        }
        return item;
      })
    );
  };

  const fetchTestListHandler = async () => {
    try {
      const response = await getAllTestListApi();
      setTestSelectList(response?.data?.data);

      dispatch(fetchTestListOptions(response?.data?.data));
    } catch (error) {
      toast.error(error?.message);
    }
  };

  React.useEffect(() => {
    fetchTestListHandler();
  }, []);

  return (
    <>
      <Header />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card className="shadow" style={{ minHeight: "50vh" }}>
              <CardHeader
                className={width < 768 ? "border-0" : "border-0 d-flex justify-content-between"}
              >
                <div
                  style={
                    width < 768
                      ? {
                          display: "flex",
                          justifyContent: "space-between",
                          marginBottom: "10px"
                        }
                      : {}
                  }
                >
                  <h3 className="mb-0">Psychologists</h3>
                  <ExportButton
                    fileName="Psychologists.csv"
                    headers={["Name, Email, Phone, Verified, Status"]}
                    fileData={exportedData}
                  >
                    Export Data
                  </ExportButton>
                </div>
                <Search
                  onSubmitHandler={onSearchHandler}
                  search={middleSearch}
                  onChangeHandler={(value) => setMiddleSearch(value)}
                />
              </CardHeader>
              {isLoading ? (
                <React.Fragment>
                  <div className="post_error_loading">
                    <Loader />
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {error ? (
                    <React.Fragment>
                      <div className="post_error_loading">{error}</div>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Table className="align-items-center table-flush" responsive>
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Verified</th>
                            <th scope="col">Phone</th>
                            <th scope="col">Status</th>
                            <th scope="col">Action</th>
                            <th scope="col">Select Assessments</th>
                          </tr>
                        </thead>
                        <tbody>
                          {psychologistData?.length > 0 &&
                            psychologistData?.map((psycho) => {
                              return (
                                <tr
                                  style={{
                                    cursor: "pointer"
                                  }}
                                  key={psycho?.id}
                                >
                                  <td
                                    onClick={() => navigateToPatientsListPage(psycho?.id)}
                                    style={{
                                      textTransform: "capitalize",
                                      color: `${psycho?.deletedAt ? "red" : " "}`
                                    }}
                                  >
                                    {psycho?.name}
                                  </td>
                                  <td
                                    style={{
                                      color: `${psycho?.deletedAt ? "red" : " "}`
                                    }}
                                    onClick={() => navigateToPatientsListPage(psycho?.id)}
                                  >
                                    {psycho?.email}
                                  </td>

                                  <td>
                                    <span
                                      style={
                                        psycho?.status
                                          ? {
                                              backgroundColor: "#e5f2e5",
                                              padding: "5px 10px",
                                              color: "green",
                                              fontWeight: "600",
                                              borderRadius: "5px"
                                            }
                                          : {
                                              backgroundColor: "#ffe5e5",
                                              color: "red",
                                              fontWeight: "600",
                                              padding: "5px 10px",
                                              borderRadius: "5px"
                                            }
                                      }
                                    >
                                      {psycho?.status ? "Verified" : "Not verified"}
                                    </span>
                                  </td>

                                  <td
                                    style={{
                                      color: `${psycho?.deletedAt ? "red" : " "}`
                                    }}
                                    onClick={() => navigateToPatientsListPage(psycho?.id)}
                                  >
                                    {phoneFormat(psycho?.mobile)}
                                  </td>

                                  {psycho?.deletedAt ? (
                                    <td>
                                      <span
                                        style={{
                                          backgroundColor: "#ffe5e5",
                                          color: "red",
                                          fontWeight: "600",
                                          padding: "5px 10px",
                                          borderRadius: "5px"
                                        }}
                                      >
                                        Deleted
                                      </span>
                                    </td>
                                  ) : (
                                    <td>
                                      <span
                                        style={
                                          psycho?.isVerified
                                            ? {
                                                backgroundColor: "#e5f2e5",
                                                padding: "5px 10px",
                                                color: "green",
                                                fontWeight: "600",
                                                borderRadius: "5px"
                                              }
                                            : {
                                                backgroundColor: "#ffe5e5",
                                                color: "red",
                                                fontWeight: "600",
                                                padding: "5px 10px",
                                                borderRadius: "5px"
                                              }
                                        }
                                      >
                                        {psycho?.isVerified ? "Active" : "Inactive"}
                                      </span>
                                    </td>
                                  )}

                                  {psycho?.deletedAt ? (
                                    <>
                                      <td>
                                        <span className="mx-2">---</span>
                                      </td>
                                      <td>
                                        <span
                                          style={{
                                            marginLeft: "30px"
                                          }}
                                        >
                                          ---
                                        </span>
                                      </td>
                                    </>
                                  ) : (
                                    <>
                                      <td>
                                        {psycho?.isVerified ? (
                                          <TooltipIcon
                                            target={"disabledUser" + psycho?.id}
                                            label="Deactivate psychologist"
                                            disabled={!psycho?.status}
                                            iconClass="fas fa-ban inactive_icons"
                                            onclickHandler={() =>
                                              psychologistStatusChangeHandler(psycho, false)
                                            }
                                            style={{}}
                                          />
                                        ) : (
                                          <TooltipIcon
                                            target={"enableUser" + psycho?.id}
                                            label="Activate psychologist"
                                            disabled={!psycho?.status}
                                            iconClass="fas fa-check active_icons"
                                            onclickHandler={() =>
                                              psychologistStatusChangeHandler(psycho, true)
                                            }
                                            style={{
                                              padding: "5px 7px"
                                            }}
                                          />
                                        )}
                                      </td>
                                      <td className="pl-5">
                                        <i
                                          className="fas fa-tasks fa-2x"
                                          onClick={() => {
                                            setSelectedPsycho(psycho);
                                            setOpenCategoryModal(true);
                                          }}
                                        ></i>
                                      </td>
                                    </>
                                  )}

                                  {openCategoryModal && selectedPsycho && (
                                    <SelectAssessmentModal
                                      toggleModal={toggleCategoryModal}
                                      isOpen={openCategoryModal}
                                      onSubmitHandler={onSubmitHandler}
                                      category={null}
                                      selectedPsycho={selectedPsycho}
                                      assessmentList={testSelectList}
                                    />
                                  )}
                                </tr>
                              );
                            })}
                        </tbody>
                      </Table>
                      {!error && psychologistData?.length === 0 && (
                        <div className="no_posts">No psychologists found.</div>
                      )}
                      {psychologistData && psychologistData?.length > 0 && (
                        <CardFooter className="py-4">
                          <nav aria-label="..." className="post_footer">
                            <NewPagination
                              totalRecords={totalPsychologists}
                              setCurrentPage={setCurrentPage}
                              pageLimit={postsPerPage}
                              currentPage={currentPage}
                              pageNeighbours={1}
                            />
                          </nav>
                        </CardFooter>
                      )}
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default PsychologistsPage;

import React from "react";
import { Button, UncontrolledTooltip } from "reactstrap";

function TooltipIcon(props) {
  const { target, onclickHandler, iconClass, label, style, disabled = false } = props;

  return (
    <>
      <UncontrolledTooltip delay={0} placement="top" target={target}>
        {label}
      </UncontrolledTooltip>
      <Button
        disabled={disabled}
        id={target}
        onClick={onclickHandler}
        style={{
          background: "transparent",
          boxShadow: "none",
          border: "none",
          transition: "none",
          opacity: disabled ? 0.4 : 1,
          padding: "0"
        }}
      >
        <i className={iconClass} style={style}></i>
      </Button>
    </>
  );
}

export default TooltipIcon;

import axiosInstance from "./AxiosInstance";

export const getLoggedInUserApi = (userData) => axiosInstance.post("api/auth/login", userData);

export const forgotPasswordUserApi = (userData) =>
  axiosInstance.post("api/auth/forgot-password", userData);

export const verifyOptUserApi = (userData) => axiosInstance.post("api/auth/verify-otp", userData);

export const resetPasswordUserApi = (userData) =>
  axiosInstance.post("api/auth/reset-password", userData);

export const loggedOutUserApi = () => axiosInstance.get("api/auth/logout");

export const getUsersListApi = (prms) =>
  axiosInstance.get("auth/user/list", {
    params: {
      page: prms?.page || 1,
      limit: prms.limit || 10,
      search: prms?.search || ""
    }
  });

export const postConfigureDiscountApi = (id, userData) =>
  axiosInstance.post(`auth/add-discount/${id}`, userData);

export const changePasswordUserApi = (userData) =>
  axiosInstance.post("api/users/change-password", userData);

import React from "react";
import { Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";

const Search = ({ onSubmitHandler, search, onChangeHandler }) => {
  return (
    <>
      <Form
        role="form"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <FormGroup className="d-flex">
          <InputGroup className="input-group-alternative">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="ni ni-zoom-split-in" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder="Search"
              type="text"
              value={search}
              onChange={(e) => onChangeHandler(e.target.value)}
            />
          </InputGroup>
        </FormGroup>
      </Form>
    </>
  );
};

export default Search;

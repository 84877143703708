import React from "react";
import { Button } from "reactstrap";

function ExportButton(props) {
  const { headers, fileName, fileData } = props;

  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType });

    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  const exportToCsv = (e) => {
    e.preventDefault();

    downloadFile({
      data: [...headers, ...fileData].join("\n"),
      fileName: fileName,
      fileType: "text/csv"
    });
  };

  return (
    <Button
      color="main-color"
      type="button"
      outline
      className="add_cabinet_button"
      onClick={exportToCsv}
    >
      Export Data
    </Button>
  );
}

export default ExportButton;

/*eslint-disable*/
import React from "react";
import { debounce } from "lodash";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardHeader, CardFooter, Table, Container, Row, Button } from "reactstrap";
import * as XLSX from "xlsx";

import useViewport from "hooks/useViewport";

import Header from "components/Headers/Header.jsx";
import TooltipIcon from "components/TooltipIcon/TooltipIcon";
import Loader from "components/Loader/Loader";
import ExportButton from "components/ExportButton/ExportButton";
import NewPagination from "components/NewPagination/NewPagination";
import Search from "components/Search/Search";

import { selectUser } from "redux/user/selectors";
import { selectTests } from "redux/test/selectors";

import {
  fetchTestsThunkAction,
  deactivateTestThunkAction,
  activateTestThunkAction,
  fetchTestsExportDataThunkAction
} from "redux/test/actions";

import { formatSectionNames } from "utils/getSection";
import { getAssessmentExportApi } from "services/TestServices";
import moment from "moment";
import getAge from "utils/getAge";
import Switcher from "components/Switcher/Switcher";

const TestsPage = () => {
  const [testData, setTestData] = React.useState([]);
  const [error, setError] = React.useState(null);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [postsPerPage] = React.useState(10);
  const [search, setsSearch] = React.useState("");
  const [middleSearch, setMiddleSearch] = React.useState("");
  const [exportedData, setExportedData] = React.useState([]);

  let history = useHistory();
  const { width } = useViewport();
  const dispatch = useDispatch();
  const { role } = useSelector(selectUser);

  const { isLoading, tests, totalTests, exportTestData } = useSelector(selectTests);

  const onSuccess = () => {
    setError(null);
  };

  const onError = (error) => {
    setError(error);
  };

  const onSearchHandler = (keyword) => {
    setCurrentPage(1);
    setsSearch(keyword);
  };

  const onUpdateError = (error) => {
    toast.error(error);
  };

  const onUpdateSuccess = () => {
    dispatch(
      fetchTestsThunkAction(
        {
          search: search || "",
          limit: postsPerPage,
          page: currentPage
        },
        onSuccess,
        onError
      )
    );
  };

  const testStatusChangeHandler = async (test, statusLabel) => {
    if (statusLabel === "Inactive") {
      await dispatch(await deactivateTestThunkAction(test?.id, onUpdateSuccess, onUpdateError));
    } else {
      await dispatch(await activateTestThunkAction(test?.id, onUpdateSuccess, onUpdateError));
    }
  };

  const navigateToUpdateTest = (id) => {
    history.push(`/admin/assessment/update/${id}`);
  };

  React.useEffect(() => {
    dispatch(
      fetchTestsThunkAction(
        {
          search,
          limit: postsPerPage,
          page: currentPage
        },
        onSuccess,
        onError
      )
    );
  }, [currentPage, search]);

  React.useEffect(() => {
    setTestData(tests);
  }, [tests, tests.length]);

  const debouncedSave = React.useCallback(
    debounce((searchText) => {
      setsSearch(searchText);
      setCurrentPage(1);
    }, 700),
    [] // will be created only once initially
  );

  React.useEffect(() => {
    debouncedSave(middleSearch);
  }, [middleSearch]);

  React.useEffect(() => {
    dispatch(fetchTestsExportDataThunkAction());
  }, []);

  React.useEffect(() => {
    const formatedData = exportTestData.map((item) => ({
      title: item.title.replace(",", " |"),
      score: item.assignmentCount,
      status: item.status ? "Active" : "Inactive"
    }));

    const csvData = formatedData.reduce((acc, item) => {
      const { title, score, status } = item;
      acc.push([title, score, status].map((d) => `${d}`).join(","));
      return acc;
    }, []);

    setExportedData(csvData);
  }, [exportTestData]);

  const fetchExportDataHandler = async (id) => {
    try {
      const { data } = await getAssessmentExportApi(id);

      if (!data?.data?.length) {
        return;
      }

      const assessmentName = data?.data?.length ? data?.data[0]?.assessment?.title : "";

      const formatedData = data.data?.map((item, index) => {
        const questionsData = {};

        const timingData = {};

        item.data.forEach((elem, i) => {
          questionsData[`${elem?.serialNumber} `] = elem?.subjectAnswer;
          questionsData[`${elem?.serialNumber}SCORE`] = elem?.subjectScore ? elem?.subjectScore : elem?.subjectAnswer === "No Response" ? 0 : null;
        });

        item?.assessment?.assignments[index]?.result?.sections?.forEach((sec, j, secArr) => {
          timingData[secArr?.length !== 1 ? `STARTPART${j + 1}` : `START TIME`] = sec?.timings
            ?.length
            ? moment(sec?.timings[0]?.start).format("hh:mm:ss A")
            : "";
          timingData[secArr?.length !== 1 ? `ENDPART${j + 1}` : `END TIME`] = sec?.timings?.length
            ? moment(sec?.timings[sec.timings?.length - 1]?.end).format("hh:mm:ss A")
            : "";
        });

        return {
          NAME: item?.subject?.name,
          DOB: moment(item?.subject?.dob).format("MM/DD/YYYY"),
          AGE: getAge(item?.subject?.dob),
          GENDER: item?.subject?.gender[0].toUpperCase() + item?.subject?.gender.slice(1) || "Male",
          LANGUAGES: item?.assessment?.languageId === "en" ? "English" : "Spanish",
          "TEST ADMIN": item?.assessment?.title,
          "TEST DATE": moment(item?.examinationTime).format("MM/DD/YYYY"),
          LOCATION: item?.examinationPlace,
          EXAMINER: item?.psychologist?.name,
          ...questionsData,
          ...timingData
        };
      });

      const wsArr = [];

      wsArr.push(XLSX.utils.json_to_sheet(formatedData));

      wsArr[0]["!cols"] = [
        { wpx: 100 },
        { wpx: 60 },
        { wpx: 35 },
        { wpx: 40 },
        { wpx: 70 },
        { wpx: 80 },
        { wpx: 75 },
        { wpx: 80 },
        { wpx: 90 }
      ];
      const wb = XLSX.utils.book_new();

      XLSX.utils.book_append_sheet(wb, wsArr[0], "Subject Details");

      XLSX.writeFile(wb, `${assessmentName}.xlsx`);
    } catch (error) {
      console.log(error?.message);
    }
  };

  return (
    <>
      <Header />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card className="shadow" style={{ minHeight: "50vh" }}>
              <CardHeader
                className={width < 768 ? "border-0" : "border-0 d-flex justify-content-between"}
              >
                <div
                  style={
                    width < 768
                      ? {
                          display: "flex",
                          justifyContent: "space-between",
                          marginBottom: "10px"
                        }
                      : {}
                  }
                >
                  <h3 className="mb-0">Assessments</h3>
                  {role === "admin" && (
                    <Link to="/admin/assessment/add/questionTypes">
                      <Button color="main-color" type="button" className="add_cabinet_button mr-2">
                        Create Assessment
                      </Button>
                    </Link>
                  )}
                  <ExportButton
                    fileName="Assessments.csv"
                    headers={["Name, Administrations, Status"]}
                    fileData={exportedData}
                  >
                    Export Data
                  </ExportButton>
                </div>
                <Search
                  onSubmitHandler={onSearchHandler}
                  search={middleSearch}
                  onChangeHandler={(value) => setMiddleSearch(value)}
                />
              </CardHeader>
              {isLoading ? (
                <React.Fragment>
                  <div className="post_error_loading">
                    <Loader />
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {error ? (
                    <React.Fragment>
                      <div className="post_error_loading">{error}</div>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Table className="align-items-center table-flush" responsive>
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Administrations</th>

                            <th scope="col">Status</th>
                            {role === "admin" && <th scope="col">Edit</th>}
                            <th scope="col">Export</th>
                            {role === "admin" && <th scope="col">Action</th>}
                          </tr>
                        </thead>
                        <tbody>
                          {testData?.length > 0 &&
                            testData?.map((user) => {
                              const totalQuestions = user?.sections?.reduce(
                                (count, sec) => count + sec?.questions.length,
                                0
                              );

                              return (
                                <tr key={user?.id}>
                                  <td
                                    style={{
                                      textTransform: "capitalize"
                                    }}
                                  >
                                    {user?.title}
                                  </td>

                                  <td>{user?.assignmentCount}</td>

                                  <td>
                                    <span
                                      style={
                                        user?.status
                                          ? {
                                              backgroundColor: "#e5f2e5",
                                              padding: "5px 10px",
                                              color: "green",
                                              fontWeight: "600",
                                              borderRadius: "5px"
                                            }
                                          : {
                                              backgroundColor: "#ffe5e5",
                                              color: "red",
                                              fontWeight: "600",
                                              padding: "5px 10px",
                                              borderRadius: "5px"
                                            }
                                      }
                                    >
                                      {user?.status ? "Active" : "Inactive"}
                                    </span>
                                  </td>
                                  {role === "admin" && (
                                    <td>
                                      <TooltipIcon
                                        target={"editTest" + user?.id}
                                        label="Edit"
                                        iconClass="fas fa-edit"
                                        onclickHandler={() => navigateToUpdateTest(user?.id)}
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "0.9rem"
                                        }}
                                      />
                                    </td>
                                  )}
                                  <td>
                                    {user?.assignmentCount ? (
                                      <i
                                        className="fas fa-file-download"
                                        style={{
                                          transform: "scale(1.2)",
                                          marginLeft: "1rem",
                                          cursor: "pointer"
                                        }}
                                        onClick={() => fetchExportDataHandler(user?.id)}
                                      />
                                    ) : (
                                      <i
                                        className="fas fa-file-download"
                                        style={{
                                          transform: "scale(1.2)",
                                          marginLeft: "1rem",
                                          cursor: "pointer",
                                          opacity: 0.5
                                        }}
                                      />
                                    )}
                                  </td>
                                  {role === "admin" && (
                                    <td>
                                      <Switcher
                                        onChangeHandler={testStatusChangeHandler}
                                        selectedItem={user}
                                      />
                                    </td>
                                  )}
                                </tr>
                              );
                            })}
                        </tbody>
                      </Table>
                      {!error && testData?.length === 0 && (
                        <div className="no_posts">No assessments found.</div>
                      )}
                      {testData && testData?.length > 0 && (
                        <CardFooter className="py-4">
                          <nav aria-label="..." className="post_footer">
                            <NewPagination
                              totalRecords={totalTests}
                              setCurrentPage={setCurrentPage}
                              pageLimit={postsPerPage}
                              currentPage={currentPage}
                              pageNeighbours={1}
                            />
                          </nav>
                        </CardFooter>
                      )}
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default TestsPage;

import React from "react";
import CreatableSelect from "react-select/creatable";

function SelectSearch(props) {
  const { handleChange, placeHolder, value, options } = props;

  return (
    <>
      <CreatableSelect
        styles={{
          control: (base) => ({
            ...base,
            border: "1 solid #e9ecef",
            boxShadow: "none"
          })
        }}
        className="react-select-style"
        isClearable
        onChange={handleChange}
        placeholder={placeHolder}
        options={options}
        value={value}
      />
    </>
  );
}

export default SelectSearch;

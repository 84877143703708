/*eslint-disable*/
import React from "react";
import * as XLSX from "xlsx";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { getReportDetailByIdApi } from "../services/TestServices";

import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Button,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

import useViewport from "hooks/useViewport";

import Header from "components/Headers/Header.jsx";
import GoBackButton from "components/GoBackButton/GoBackButton";

import { fetchPatientByIdThunkAction } from "redux/patients/actions";
import { selectPatients } from "redux/patients/selectors";
import { selectUser } from "redux/user/selectors";

import "../assets/css/PatientDetailsPage.css";
import { getSectionDetails } from "utils/getSection";
import Dropdown from "components/Dropdown/Dropdown";
import { pdfData } from "./pdf";
import Loader from "components/Loader/Loader";
import getAge from "utils/getAge";
import moment from "moment";

const PatientDetailsPage = () => {
  const [patientData, setPatientData] = React.useState(null);
  const { width } = useViewport();
  const dispatch = useDispatch();
  let { id: patientId } = useParams();
  const { selectedPatient, isLoading, error } = useSelector(selectPatients);
  const { token, role: userRole } = useSelector(selectUser);

  React.useEffect(() => {
    if (patientId) {
      dispatch(fetchPatientByIdThunkAction(patientId, "examinationTime"));
    }
  }, [patientId]);

  React.useEffect(() => {
    if (selectedPatient) {
      setPatientData(selectedPatient);
    }
  }, [selectedPatient]);

  const capitalize = (s) => {
    return s[0].toUpperCase() + s.slice(1);
  };

  const exportData = () => {
    const assessmentDetailData = patientData?.assignments?.map((assignment) => {
      return {
        "Assessment Name": assignment?.assessment?.title,
        // Status: capitalize(assignment?.status),
        // "Subject Score": assignment?.score,
        // "Total Score": assignment?.assessment?.score,
        Location: assignment?.examinationPlace,
        "Examination Date": moment(`${assignment?.examinationTime}`).format("MM/DD/YYYY"),
        Type: assignment?.type[0].toUpperCase() + assignment?.type.slice(1)
      };
    });

    const subjectDetailData = [
      {
        "First Name": patientData?.firstName,
        "Last Name": patientData?.lastName,
        "Date of Birth": patientData?.dob,
        Age: getAge(patientData?.dob),
        Gender: capitalize(patientData?.gender || "Male")
      }
    ];

    const mergedSubjectDetailsData = [].concat(subjectDetailData, assessmentDetailData);

    const assessmentData = patientData?.assignments?.map((assignment) => {
      return {
        sheetName: assignment?.assessment?.title,
        sections: assignment?.assessment?.sections
          .map((item) => {
            const newData = [
              {
                title: "",
                answer: ""
              },
              {
                title: getSectionDetails(item)?.label + " ( " + item?.title + ")",
                answer: ""
              },
              ...item?.questions,
              {
                title: "",
                answer: ""
              }
            ];
            return newData;
          })
          .flat()
          .map((question) => {
            return {
              Question: question?.title,
              Answer: question?.answer
            };
          })
      };
    });

    const wsArr = [];
    var wscols = [{ wpx: 600 }, { wpx: 300 }];

    wsArr.push(XLSX.utils.json_to_sheet(mergedSubjectDetailsData));
    wsArr[0]["!cols"] = [
      { wpx: 70 },
      { wpx: 70 },
      { wpx: 70 },
      { wpx: 40 },
      { wpx: 70 },
      { wpx: 80 },
      { wpx: 150 },
      { wpx: 70 },
      { wpx: 70 },
      { wpx: 70 },
      { wpx: 100 }
    ];

    const wb = XLSX.utils.book_new();

    for (let i = 0; i < assessmentData.length; i++) {
      wsArr.push(XLSX.utils.json_to_sheet(assessmentData[i].sections));
    }

    XLSX.utils.book_append_sheet(wb, wsArr[0], "Subject Details");

    // for (let i = 0; i < assessmentData.length; i++) {
    //   wsArr[i + 1]["!cols"] = wscols;
    //   XLSX.utils.book_append_sheet(
    //     wb,
    //     wsArr[i + 1],
    //     `${i + 1}.${assessmentData[i].sheetName.substring(0, 11)}..`
    //   );
    // }

    XLSX.writeFile(wb, "SubjectDetails.xlsx");
  };

  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const ExportPdf = async (id) => {
    try {
      const { data } = await getReportDetailByIdApi(id);
      const myPdfData = pdfData(data.data, patientData);
      const pdf = pdfMake.createPdf(myPdfData);
      pdf.open();
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <>
      <Header />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card className="shadow" style={{ minHeight: "50vh" }}>
              <CardHeader
              // className={width < 768 ? "border-0" : "border-0 d-flex justify-content-between"}
              >
                <div className="d-inline-flex flex-column align-items-start float-left">
                  <h3 className="mb-0 mr-4">Subject Details Page</h3>

                  <div>
                    <Button
                      color="main-color"
                      type="button"
                      outline
                      className="add_cabinet_button"
                      onClick={exportData}
                    >
                      Export Data
                    </Button>
                  </div>
                </div>
                <div className={width < 450 ? "float-left mt-2" : " float-right"}>
                  <GoBackButton />
                </div>
              </CardHeader>

              <CardBody className="">
                <React.Fragment>
                  {isLoading ? (
                    <div className="post_error_loading">
                      <Loader />
                    </div>
                  ) : null}
                  {error ? <div className="post_error_loading">{error}</div> : null}
                  {patientData ? (
                    <React.Fragment>
                      <div
                        style={{
                          display: "flex",
                          marginBottom: "0.5rem"
                        }}
                      >
                        <div
                          style={{
                            marginRight: "10px"
                          }}
                        >
                          <b>Subject Name: </b>
                        </div>
                        <div>
                          {patientData?.lastName} {patientData?.firstName}{" "}
                          {patientData?.middleName[0].toUpperCase()}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          marginBottom: "0.5rem"
                        }}
                      >
                        <div
                          style={{
                            marginRight: "10px"
                          }}
                        >
                          <b>Date of Birth: </b>
                        </div>
                        <div>{patientData?.dob}</div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          marginBottom: "0.5rem"
                        }}
                      >
                        <div
                          style={{
                            marginRight: "10px"
                          }}
                        >
                          <b>Gender: </b>
                        </div>
                        <div className="text-capitalize">
                          {patientData?.gender ? patientData?.gender : "Male"}
                        </div>
                      </div>

                      <h3
                        style={{
                          margin: "1rem 0"
                        }}
                      >
                        Assessment
                      </h3>
                      <Table className="align-items-center table-flush" responsive>
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">Title</th>
                            {/* <th scope="col">Type</th> */}
                            <th scope="col">Status</th>
                            <th scope="col">Date of Evaluation</th>
                            <th scope="col">Location of testing</th>
                            {userRole === "psychologist" ? (
                              <th scope="col" className=" text-center">
                                Action
                              </th>
                            ) : null}
                            {userRole !== "psychologist" ? <th scope="col">Action</th> : null}
                          </tr>
                        </thead>
                        <tbody className="thead-light">
                          {patientData?.assignments?.map((assignment) => {
                            const isViewOnly = ["scored"].includes(assignment?.status);
                            return (
                              <tr
                                key={assignment?.id}
                                style={{
                                  textTransform: "capitalize"
                                }}
                              >
                                <td>{assignment?.assessment?.title}</td>
                                {/* <td>{assignment?.type}</td> */}
                                <td>
                                  {assignment.status === "assigned"
                                    ? "Assigned"
                                    : assignment.status === "completed"
                                    ? "Administered"
                                    : assignment.status === "scored"
                                    ? "Verified"
                                    : assignment.status}
                                </td>
                                <td>
                                  {moment(`${assignment?.examinationTime}`).format("MM/DD/YYYY")}
                                </td>
                                <td>{assignment?.examinationPlace}</td>
                                {userRole === "psychologist" ? (
                                  <td>
                                    {assignment?.type === "app" &&
                                    ["assigned", "inprogress"].includes(
                                      assignment?.status
                                    ) ? null : (
                                      <>
                                        <Dropdown>
                                          <DropdownMenu className="" right>
                                            {assignment?.status === "assigned" &&
                                              assignment?.type === "computer" && (
                                                <Link
                                                  to={`/admin/start/test/assessment/${assignment?.assessment?.id}/assignment/${assignment?.id}/patient/${patientData?.id}`}
                                                >
                                                  <DropdownItem>Start Test</DropdownItem>
                                                </Link>
                                              )}
                                            {assignment?.status === "inprogress" &&
                                              assignment?.type === "computer" && (
                                                <Link
                                                  to={`/admin/start/test/assessment/${assignment?.assessment?.id}/assignment/${assignment?.id}/patient/${patientData?.id}`}
                                                >
                                                  <DropdownItem>Resume Test</DropdownItem>
                                                </Link>
                                              )}

                                            {["completed"].includes(assignment?.status) && (
                                              <Link
                                                to={`/admin/update/test/assessment/${assignment?.assessment?.id}/assignment/${assignment?.id}/patient/${patientData?.id}`}
                                              >
                                                <DropdownItem>Edit</DropdownItem>
                                              </Link>
                                            )}
                                            {assignment?.status === "completed" && (
                                              <Link
                                                to={`/admin/view/test/assessment/${assignment?.assessment?.id}/assignment/${assignment?.id}/patient/${patientData?.id}`}
                                              >
                                                <DropdownItem>View/Verify</DropdownItem>
                                              </Link>
                                            )}

                                            {isViewOnly && (
                                              <>
                                                <Link
                                                  to={`/admin/view/test/assessment/${assignment?.assessment?.id}/assignment/${assignment?.id}/patient/${patientData?.id}`}
                                                >
                                                  <DropdownItem>View</DropdownItem>
                                                </Link>
                                                <DropdownItem
                                                  onClick={() => ExportPdf(assignment?.id)}
                                                >
                                                  Print Record
                                                </DropdownItem>
                                              </>
                                            )}
                                          </DropdownMenu>
                                        </Dropdown>
                                      </>
                                    )}
                                  </td>
                                ) : null}

                                {userRole !== "psychologist" ? (
                                  <td>
                                    {isViewOnly && (
                                      <>
                                        <Dropdown>
                                          <DropdownMenu className="dropdown-menu-arrow" right>
                                            <Link
                                              to={`/admin/view/test/assessment/${assignment?.assessment?.id}/assignment/${assignment?.id}/patient/${patientData?.id}`}
                                            >
                                              <DropdownItem>View</DropdownItem>
                                            </Link>
                                            <DropdownItem onClick={() => ExportPdf(assignment?.id)}>
                                              Print Record
                                            </DropdownItem>
                                          </DropdownMenu>
                                        </Dropdown>
                                      </>
                                    )}
                                    {assignment?.status === "completed" && (
                                      <Dropdown>
                                        <DropdownMenu className="dropdown-menu-arrow" right>
                                          <Link
                                            to={`/admin/view/test/assessment/${assignment?.assessment?.id}/assignment/${assignment?.id}/patient/${patientData?.id}`}
                                          >
                                            <DropdownItem>View</DropdownItem>
                                          </Link>
                                        </DropdownMenu>
                                      </Dropdown>
                                    )}
                                  </td>
                                ) : null}
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </React.Fragment>
                  ) : null}
                </React.Fragment>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default PatientDetailsPage;

import {
  FETCH_PATIENT_EXPORT_DATA,
  FETCH_PATIENT_FAILURE,
  FETCH_PATIENT_REQUEST,
  FETCH_PATIENT_SUCCESS,
  SELECTED_PATIENT_FAILURE,
  SELECTED_PATIENT_REQUEST,
  SELECTED_PATIENT_SUCCESS
} from "./types";

const initialState = {
  isLoading: false,
  error: "",
  patients: [],
  totalPatients: 0,
  selectedPatient: null,
  exportPatientData: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PATIENT_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case FETCH_PATIENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        patients: action.payload.users,
        totalPatients: action.payload.totalUsers,
        selectedPatient: null,
        error: ""
      };
    case FETCH_PATIENT_EXPORT_DATA:
      return {
        ...state,
        exportPatientData: action.payload
      };
    case FETCH_PATIENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        patients: [],
        totalPatients: 0,
        selectedPatient: null,
        error: action.payload
      };

    case SELECTED_PATIENT_REQUEST:
      return {
        ...state,
        isLoading: true,
        selectedPatient: null,
        error: ""
      };
    case SELECTED_PATIENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        selectedPatient: action.payload,
        error: ""
      };
    case SELECTED_PATIENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        selectedPatient: null,
        error: action.payload
      };
    default:
      return state;
  }
};

export default reducer;

import { Box } from "@material-ui/core";
import { booleanQuestionTypeOptions } from "Data/Tests";
import React, { useState } from "react";
import { Button, Col, Form, FormGroup, Input, Modal, Row } from "reactstrap";
import { v4 as uuidv4 } from "uuid";

const questionTypeOPtions = [
  {
    id: 1,
    type: "text",
    label: "Open Ended",
    title: "",
    answer: "",
    score: null,
    options: null,
    correctAnswerFeedback: "",
    incorrectAnswerFeedback: ""
  },
  {
    id: 2,
    type: "mcq",
    label: "Multiple Choice",
    title: "",
    answer: "",
    score: null,
    options: [
      {
        id: "0",
        label: "",
        value: ""
      },
      {
        id: "No Response",
        value: "No Response",
        label: "No Response"
      }
    ],
    correctAnswerFeedback: "",
    incorrectAnswerFeedback: ""
  },
  {
    id: 3,
    label: "True/False",
    type: "true-false",
    title: "",
    answer: "",
    score: null,
    options: [
      ...JSON.parse(JSON.stringify(booleanQuestionTypeOptions)),
      {
        id: "No Response",
        value: "No Response",
        label: "No Response"
      }
    ],
    correctAnswerFeedback: "",
    incorrectAnswerFeedback: ""
  }
];

function QuestionTypeModal({ toggleModal, isOpen, onSubmitHandler = () => {} }) {
  return (
    <>
      <Row>
        <Col md="12">
          <Modal
            backdrop="static"
            className="modal-dialog-centered"
            isOpen={isOpen}
            toggle={() => toggleModal(!isOpen)}
          >
            <div className="modal-header">
              <h2 className="modal-title " id="modal-title-default">
                Select Question Type
              </h2>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => toggleModal(false)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              <Form role="form">
                {questionTypeOPtions.map((item) => {
                  return (
                    <Box
                      onClick={(e) => {
                        e.stopPropagation();
                        onSubmitHandler(item);
                        toggleModal(false);
                      }}
                      key={item.id}
                      className="border question-type border-primary p-2 w-100 font-weight-bold mb-2"
                      style={{}}
                    >
                      {item.label}
                    </Box>
                  );
                })}
              </Form>
            </div>
          </Modal>
        </Col>
      </Row>
    </>
  );
}

export default QuestionTypeModal;

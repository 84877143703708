import { Box } from "@material-ui/core";
import { booleanQuestionTypeOptions } from "Data/Tests";
import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Col, Form, Input, Modal, ModalFooter, Row } from "reactstrap";

function SelectAssessmentModal({
  toggleModal,
  isOpen,
  onSubmitHandler = () => {},
  selectedPsycho,
  assessmentList = []
}) {
  const [testList, setTestList] = useState([]);

  const [selectTestList, setSelectTestList] = useState([]);

  useEffect(() => {
    setTestList(assessmentList);
  }, [assessmentList]);

  useEffect(() => {
    setSelectTestList(selectedPsycho?.connectedAssessmentIds);
  }, [selectedPsycho]);

  return (
    <>
      <Row>
        <Col md="12">
          <Modal
            size="lg"
            backdrop="static"
            className="modal-dialog-centered"
            isOpen={isOpen}
            toggle={() => toggleModal(!isOpen)}
          >
            <div className="modal-header">
              <h2 className="modal-title " id="modal-title-default">
                Select Assessments
              </h2>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => toggleModal(false)}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div
              className="modal-body assessment_list_modal"
              style={{
                maxHeight: "500px",
                overflowY: "scroll"
              }}
            >
              <Form role="form">
                {testList?.map((item) => {
                  return (
                    <Box
                      onClick={(e) => {
                        e.stopPropagation();

                        if (selectTestList.includes(item?.id)) {
                          console.log("remove");
                          setSelectTestList((preData) =>
                            preData.filter((data) => data !== item?.id)
                          );
                        } else {
                          console.log("add");
                          setSelectTestList((preData) => [...preData, item?.id]);
                        }
                      }}
                      key={item.id}
                      className="border question-type border-primary p-2 w-100 font-weight-bold mb-2 d-flex justify-content-between align-items-center"
                    >
                      <span>{item?.title}</span>
                      {selectTestList?.includes(item?.id) ? (
                        <i
                          className="fas fa-check mx-3"
                          style={{
                            color: " green",
                            fontSize: "1rem"
                          }}
                        ></i>
                      ) : null}
                    </Box>
                  );
                })}
              </Form>
            </div>
            <ModalFooter className="d-flex justify-content-center">
              <Button
                color="primary"
                onClick={() => {
                  onSubmitHandler(selectedPsycho?.id, selectTestList);
                  toggleModal(false);
                }}
              >
                Add
              </Button>
            </ModalFooter>
          </Modal>
        </Col>
      </Row>
    </>
  );
}

export default SelectAssessmentModal;

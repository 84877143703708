import React from "react";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import "../../assets/css/FormMenu.css";
function FormMenu(props) {
  const { menuItems = [], selected, onSelectHandler, placeHolder, disabled } = props;

  return (
    <>
      <UncontrolledDropdown
        style={{
          width: "100%"
        }}
      >
        <DropdownToggle
          caret
          color="white"
          id="dropdownMenuButton"
          className="dropdown-toggle"
          type="button"
          placeholder="Select"
          disabled={disabled}
        >
          {selected?.title || placeHolder}
        </DropdownToggle>

        <DropdownMenu
          aria-labelledby="dropdownMenuButton"
          style={{
            width: "100%"
          }}
          className="dropdown-toggle-box"
        >
          {!selected && (
            <DropdownItem disabled onClick={() => onSelectHandler()}>
              {placeHolder}
            </DropdownItem>
          )}

          {menuItems.length > 0 &&
            menuItems.map((item, idx) => {
              return (
                <DropdownItem
                  key={item.id}
                  onClick={() => onSelectHandler(selected, item)}
                  style={{
                    textTransform: "capitalize"
                  }}
                >
                  {item?.title || item?.label || ""}
                </DropdownItem>
              );
            })}
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
}

export default FormMenu;

import moment from "moment";
import toast from "react-hot-toast";
import {
  FETCH_PATIENT_EXPORT_DATA,
  FETCH_PATIENT_FAILURE,
  FETCH_PATIENT_REQUEST,
  FETCH_PATIENT_SUCCESS,
  SELECTED_PATIENT_FAILURE,
  SELECTED_PATIENT_REQUEST,
  SELECTED_PATIENT_SUCCESS
} from "./types";

import {
  getPatientsApi,
  postCreatePatientApi,
  getPatientByIdApi,
  postUpdatePatientApi,
  getPatientsExportDataApi
} from "services/PatientServices";

import { resetTestData } from "redux/test/actions";
import { deletePatientApi } from "services/PatientServices";

export const fetchPatientRequest = () => {
  return {
    type: FETCH_PATIENT_REQUEST
  };
};

export const fetchPatientSuccess = (users, totalUsers) => {
  return {
    type: FETCH_PATIENT_SUCCESS,
    payload: { users, totalUsers }
  };
};

export const fetchPatientFailure = (error) => {
  return {
    type: FETCH_PATIENT_FAILURE,
    payload: error
  };
};

export const selectedPatientRequest = () => {
  return {
    type: SELECTED_PATIENT_REQUEST
  };
};

export const selectedPatientSuccess = (patient) => {
  return {
    type: SELECTED_PATIENT_SUCCESS,
    payload: patient
  };
};

export const selectedPatientFailure = (error) => {
  return {
    type: SELECTED_PATIENT_FAILURE,
    payload: error
  };
};

export const fetchPatientsThunkAction = (params, id, onSuccess, onError) => {
  return async (dispatch) => {
    try {
      dispatch(fetchPatientRequest());
      const { data } = await getPatientsApi(params, id);

      if (data.success !== true) {
        throw new Error(data.message);
      }

      const formatedData = data.data.data.map((item) => {
        return {
          ...item,
          dob: moment(item?.dob).format("MM/DD/YYYY"),
          psychologistName:
            data.data?.psychologist?.firstName + " " + data.data?.psychologist?.lastName,
          deletedAt: item?.assignments[0]?.psychologist?.deletedAt
        };
      });

      dispatch(fetchPatientSuccess(formatedData, data.data.total));
      onSuccess();
    } catch (error) {
      dispatch(fetchPatientFailure(error?.response?.data?.message));
      onError(error?.response?.data?.message);
    }
  };
};

const fetchPatientsExportData = (data) => {
  return {
    type: FETCH_PATIENT_EXPORT_DATA,
    payload: data
  };
};

export const fetchPatientsExportDataThunkAction = (id) => {
  return async (dispatch) => {
    try {
      const { data } = await getPatientsExportDataApi(id);

      const formatedData = data.data.data.map((item) => {
        // const date = new Date(item?.dob);
        // var mydate = moment(date.toLocaleString().split(",")[0]);

        return {
          ...item,
          dob: moment(item?.dob).format("MM/DD/YYYY")
        };
      });

      if (data.success !== true) {
        throw new Error(data.message);
      }
      dispatch(fetchPatientsExportData(formatedData));
    } catch (error) {
      // onError(error?.response?.data?.message);
    }
  };
};

export const createPatientThunkAction = (postData, onCreate, onError) => {
  return async (dispatch) => {
    try {
      const promise = postCreatePatientApi(postData);

      toast.promise(promise, {
        loading: "Loading...",
        success: "Subject created successfully.",
        error: (error) => `${error?.response?.data?.message || error?.message}`
      });

      const { data } = await promise;

      if (data.success !== true) {
        throw new Error(data?.message);
      }
      onCreate();
    } catch (error) {
      onError(error?.response?.data?.message);
    }
  };
};

export const fetchPatientByIdThunkAction = (id, sortingOrder = "createdAt") => {
  return async (dispatch) => {
    try {
      dispatch(selectedPatientRequest());
      const { data } = await getPatientByIdApi(id, sortingOrder);

      if (data.success !== true) {
        throw new Error(data.message);
      }

      const date = new Date(data.data.dob);
      const mydate = moment(date.toLocaleString().split(",")[0], "DD/MM/YYYY");
      const newDate = moment(data.data.dob).format("MM/DD/YYYY");

      const formattedData = {
        ...data.data,
        dob: newDate
      };
      dispatch(selectedPatientSuccess(formattedData));
      // dispatch(resetTestData());
    } catch (error) {
      dispatch(selectedPatientFailure(error?.response?.data?.message));
      console.log(error?.response?.data?.message);
    }
  };
};

export const updatePatientThunkAction = (postData, id, onCreate, onError) => {
  return async (dispatch) => {
    try {
      const promise = postUpdatePatientApi(id, postData);

      toast.promise(promise, {
        loading: "Loading...",
        success: "Subject updated successfully.",
        error: (error) => `${error?.response?.data?.message || error?.message}`
      });

      const { data } = await promise;

      if (data.success !== true) {
        throw new Error(data?.message);
      }
      onCreate();
    } catch (error) {
      onError(error?.response?.data?.message);
    }
  };
};

export const deletePatientThunkAction = (id, onCreate, onError) => {
  return async (dispatch) => {
    try {
      const promise = deletePatientApi(id);

      toast.promise(promise, {
        loading: "Loading...",
        success: "Subject deleted successfully.",
        error: (error) => `${error?.response?.data?.message || error?.message}`
      });

      const { data } = await promise;

      if (data.success !== true) {
        throw new Error(data?.message);
      }
      onCreate();
    } catch (error) {
      onError(error?.response?.data?.message);
    }
  };
};

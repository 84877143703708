import { useSelector } from "react-redux";
import { Container, Row } from "reactstrap";
import { selectUser } from "redux/user/selectors";
import useViewport from "hooks/useViewport";
import { phoneFormat } from "utils/getSection";

const Header = () => {
  const { loggedInUser } = useSelector(selectUser);
  const { width } = useViewport();

  return (
    <>
      <div className="header bg-gradient-main-color pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div
            className="position-absolute header-body text-light font-weight-bold"
            style={{
              top: "7%",
              right: width < 1326 ? "15%" : "10%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end"
            }}
          >
            <Row>Welcome, {loggedInUser?.name}</Row>
            <Row>{loggedInUser?.email}</Row>
            <Row>{phoneFormat(loggedInUser?.mobile)}</Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Header;

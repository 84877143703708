import toast from "react-hot-toast";

import {
  FETCH_USERS_FAILURE,
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  REFRESH_STATE,
  RESET_IS_SIGNING,
  SET_IS_SIGNING,
  SET_LOGGEDIN_USER,
  SET_LOGGEDOUT_USER,
  SET_FORGET_PASSWORD_USER_EMAIL,
  RESET_FORGET_PASSWORD_USER_EMAIL,
  SET_FORGET_PASSWORD_USER_OTP,
  RESET_FORGET_PASSWORD_USER_OTP
} from "./types";

import {
  changePasswordUserApi,
  forgotPasswordUserApi,
  getLoggedInUserApi,
  getUsersListApi,
  loggedOutUserApi,
  postConfigureDiscountApi,
  resetPasswordUserApi,
  verifyOptUserApi
} from "../../services/UserServices";

export const fetchUsersRequest = () => {
  return {
    type: FETCH_USERS_REQUEST
  };
};

export const fetchUsersSuccess = (users, totalUsers) => {
  return {
    type: FETCH_USERS_SUCCESS,
    payload: { users, totalUsers }
  };
};

export const fetchUsersFailure = (error) => {
  return {
    type: FETCH_USERS_FAILURE,
    payload: error
  };
};

export const setLoggedInUser = ({ user, token, role }) => {
  return {
    type: SET_LOGGEDIN_USER,
    payload: { user, token, role }
  };
};

export const setLoggedOutUser = () => {
  return {
    type: SET_LOGGEDOUT_USER
  };
};

export const refreshState = ({ token, user, role }) => ({
  type: REFRESH_STATE,
  payload: { token, user, role }
});

export const setForgetpasswordUserEmail = (email) => {
  return {
    type: SET_FORGET_PASSWORD_USER_EMAIL,
    payload: email
  };
};

export const resetForgetpasswordUserEmail = () => {
  return {
    type: RESET_FORGET_PASSWORD_USER_EMAIL
  };
};

export const setForgetpasswordUserOtp = (otp) => {
  return {
    type: SET_FORGET_PASSWORD_USER_OTP,
    payload: otp
  };
};

export const resetForgetpasswordUserOtp = () => {
  return {
    type: RESET_FORGET_PASSWORD_USER_OTP
  };
};

export const setIsSigning = () => {
  return {
    type: SET_IS_SIGNING
  };
};

export const resetIsSigning = () => {
  return {
    type: RESET_IS_SIGNING
  };
};

export const fetchLoginUserThunkAction = ({ email, password }, onSuccess) => {
  return async (dispatch) => {
    try {
      dispatch(setIsSigning());
      const { data } = await getLoggedInUserApi({ email: email, password });
      if (data.success !== true) {
        throw new Error(data.message);
      }

      if (!(data.data.user.status && data.data.user.isVerified)) {
        throw new Error("User is not verified");
      }

      toast.success("Logged in successfully.");
      localStorage.setItem("token", data.data.accessToken);
      localStorage.setItem("role", data.data.user.role);
      localStorage.setItem("user", JSON.stringify(data.data.user));
      dispatch(
        setLoggedInUser({
          token: data.data.accessToken,
          user: data.data.user,
          role: data.data.user.role
        })
      );
      onSuccess();
      dispatch(resetIsSigning());
    } catch (error) {
      dispatch(resetIsSigning());
      toast.error(error.response?.data?.message || error.message);
    }
  };
};

export const forgetPasswordEmailThunkAction = (email, onSuccess) => {
  return async (dispatch) => {
    try {
      const { data } = await forgotPasswordUserApi({
        email: email
      });
      if (data.success !== true) {
        throw new Error(data.message);
      }
      toast.success("Please varify the OTP send to your email.");
      dispatch(setForgetpasswordUserEmail(email));
      onSuccess();
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
};

export const verifyOptThunkAction = ({ code, email }, onSuccess) => {
  return async (dispatch) => {
    try {
      const { data } = await verifyOptUserApi({ otp: code, email });

      if (data.success !== true) {
        throw new Error(data.message);
      }
      toast.success("OTP verified successfully.");
      dispatch(setForgetpasswordUserOtp(code));
      onSuccess();
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
};

export const resetPasswordThunkAction = ({ password, email, otp }, onSuccess) => {
  return async (dispatch) => {
    try {
      const { data } = await resetPasswordUserApi({ email, password, otp });

      if (data.success !== true) {
        throw new Error(data.message);
      }
      toast.success("Your password has been reset successfully.");
      dispatch(resetForgetpasswordUserEmail());
      onSuccess();
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
};

export const changePasswordThunkAction = async ({ password }, onSuccess) => {
  return async (dispatch) => {
    try {
      const { data } = await changePasswordUserApi({ password });
      toast.success("Your password has been changed successfully.");

      if (data.success !== true) {
        throw new Error(data.message);
      }

      dispatch(resetForgetpasswordUserEmail());
      onSuccess();
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };
};

export const loggingOutUserThunkAction = (onSuccess) => {
  return async (dispatch) => {
    try {
      const response = await loggedOutUserApi();
      localStorage.removeItem("token");
      localStorage.removeItem("role");
      dispatch(setLoggedOutUser());
      localStorage.removeItem("user");
      toast.success("Logged out successfully.");
      onSuccess();
    } catch (error) {
      localStorage.removeItem("token");
      localStorage.removeItem("role");
      dispatch(setLoggedOutUser());
      localStorage.removeItem("user");
      toast.error(error?.response?.data?.message);
    }
  };
};

export const fetchUsersThunkAction = (params, onSuccess, onError) => {
  return async (dispatch) => {
    try {
      dispatch(fetchUsersRequest());
      const { data } = await getUsersListApi(params);

      if (data.success !== true) {
        throw new Error(data.message);
      }
      dispatch(fetchUsersSuccess(data.user, data.userCount));
      onSuccess();
    } catch (error) {
      dispatch(fetchUsersFailure(error.message));
      onError(error.message);
    }
  };
};

export const updateConfigureDiscountThunkAction = async (
  userId,
  discountData,
  onSuccess,
  onError
) => {
  return async (dispatch) => {
    try {
      const { data } = await postConfigureDiscountApi(userId, discountData);

      if (data.success !== true) {
        throw new Error(data.message);
      }
      onSuccess();
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };
};

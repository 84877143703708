import moment from "moment";
import Select from "react-select";
import React, { useState } from "react";
import toast from "react-hot-toast";
import Flatpickr from "react-flatpickr";
import makeAnimated from "react-select/animated";
import { useHistory, useLocation, NavLink, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, CardBody, FormGroup, Form, Input, Container, Row, Col } from "reactstrap";

import Header from "components/Headers/Header";
import FormMenu from "components/FormMenu/FormMenu";
import { createPatientThunkAction } from "redux/patients/actions";
import { fetchPatientByIdThunkAction } from "redux/patients/actions";
import { selectPatients } from "redux/patients/selectors";
import { updatePatientThunkAction } from "redux/patients/actions";
import { getTestListApi } from "services/TestServices";
import { testTypeOptions } from "Data/Tests";
import useViewport from "hooks/useViewport";
import { v4 as uuidv4 } from "uuid";

import "flatpickr/dist/themes/material_green.css";
import "flatpickr/dist/flatpickr.css";
import "../assets/css/NewPatientPage.css";
import SelectSearch from "components/SelectSearch/SelectSearch";
import { setGenderHelper } from "utils/getSection";
import { selectedTest } from "redux/test/actions";
import { fetchTestListOptions } from "redux/test/actions";
import { selectTests } from "redux/test/selectors";

const AssessmentSelectionMiddleware = (props) => {
  const { testList = [] } = useSelector(selectTests);

  const { onSelectHandler, selected, menuItems = [], disabled } = props;
  return (
    <>
      <FormMenu
        menuItems={testList.map((item) => ({
          ...item,
          value: item.title,
          label: item.title
        }))}
        disabled={testList?.length > 0 ? false : true}
        selected={selected}
        onSelectHandler={onSelectHandler}
        placeHolder={testList?.length > 0 ? "Select Assessment" : "No assessment added"}
      />
    </>
  );
};

const NewPatientPage = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [gender, setGender] = useState(null);
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [tests, setTests] = useState([]);
  const [testSelectList, setTestSelectList] = useState([]);
  const [submitting, setSubmitting] = useState(false);

  const dispatch = useDispatch();
  const { width } = useViewport();
  let history = useHistory();
  const { pathname } = useLocation();
  let { id: patientId } = useParams();
  const animatedComponents = makeAnimated();
  const { selectedPatient } = useSelector(selectPatients);
  const { testList = [] } = useSelector(selectTests);

  const isEditPage = pathname.includes("update");

  const onCreate = () => {
    history.replace("/admin/subjects");
    setSubmitting(false);
  };

  const onError = (error) => {
    setSubmitting(false);
  };

  const fetchTestListHandler = async () => {
    try {
      const response = await getTestListApi();
      setTestSelectList(response?.data?.data);

      dispatch(fetchTestListOptions(response?.data?.data));
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();

    setSubmitting(true);

    if (testList?.length > 0) {
      if (!dateOfBirth || dateOfBirth === "Invalid date") {
        toast.error("Please enter date of birth.");
        setSubmitting(false);
        return;
      }

      if (!gender?.value) {
        toast.error("Please select subject's gender.");
        setSubmitting(false);
        return;
      }

      // if (tests.length === 0) {
      //   toast.error("Please select at least one test.");
      //   setSubmitting(false);
      //   return;
      // }
      const hasTypeError = tests.every((test) => {
        if (test?.type?.title) {
          return true;
        } else {
          return false;
        }
      });

      if (!hasTypeError) {
        toast.error("Please select test type.");
        setSubmitting(false);
        return;
      }

      const hasAssessmentError = tests.every((test) => {
        if (test?.title) {
          return true;
        } else {
          return false;
        }
      });

      if (!hasAssessmentError) {
        toast.error("Please select Assessment.");
        setSubmitting(false);
        return;
      }

      const hasDateError = tests.every((test) => {
        if (test?.examinationTime && test?.examinationTime !== "Invalid dateZ") {
          return true;
        } else {
          return false;
        }
      });

      if (!hasDateError) {
        toast.error("Please select test date");
        setSubmitting(false);
        return;
      }
    }

    const reqBody = {
      firstName,
      middleName,
      lastName,
      gender: gender.value,
      dob: moment(`${dateOfBirth}`).format("YYYY-MM-DD"),
      assignments:
        (testList?.length > 0 &&
          tests.map((test) => {
            return {
              id: test.id || null,
              assessment: {
                id: test.assessmentId,
                title: test.title
              },
              examinationPlace: test.examinationPlace,
              examinationTime: test.examinationTime,
              type: test.type.title
            };
          })) ||
        undefined
    };

    if (isEditPage) {
      dispatch(updatePatientThunkAction(reqBody, patientId, onCreate, onError));
    } else {
      dispatch(createPatientThunkAction(reqBody, onCreate, onError));
    }
  };

  React.useEffect(() => {
    if (patientId) {
      dispatch(fetchPatientByIdThunkAction(patientId, "createdAt"));
    }
  }, [patientId]);

  React.useEffect(() => {
    if (selectedPatient) {
      setFirstName(selectedPatient.firstName);
      setLastName(selectedPatient.lastName);
      setMiddleName(selectedPatient.middleName);
      setDateOfBirth(selectedPatient.dob);
      setGender(selectedPatient?.gender ? setGenderHelper(selectedPatient?.gender) : null);
      setTests(
        selectedPatient.assignments.map((item) => {
          return {
            id: item.id,
            assessmentId: item.assessment.id,
            status: item.status,
            title: item.assessment.title,
            examinationPlace: item.examinationPlace,
            examinationTime: item.examinationTime,
            type: testTypeOptions.find((test) => test.value === item.type),
            label: item.assessment.title,
            value: item.assessment.title,
            isFixed: ["inprogress", "completed", "scored"].includes(item.status)
          };
        })
      );
    }
  }, [selectedPatient]);

  React.useEffect(() => {
    fetchTestListHandler();
  }, []);

  const orderOptions = (values) => {
    return values && values.filter((v) => v.isFixed).concat(values.filter((v) => !v.isFixed));
  };

  const onChangeReactSelect = (value, { action, removedValue }) => {
    switch (action) {
      case "remove-value":
      case "pop-value":
        if (removedValue.isFixed) {
          return;
        }
        break;
      case "clear":
        value = testSelectList.filter((v) => v.isFixed);
        break;
      default:
        break;
    }
    value = orderOptions(value);
    setTests(value);
  };

  const styles = {
    multiValue: (base, state) => {
      return state.data.isFixed ? { ...base } : base;
    },
    multiValueLabel: (base, state) => {
      return state.data.isFixed ? { ...base, paddingRight: 6 } : base;
    },
    multiValueRemove: (base, state) => {
      return state.data.isFixed ? { ...base, display: "none" } : base;
    }
  };

  return (
    <React.Fragment>
      <Header />
      <Container className="mt--6" fluid>
        <Row>
          <div
            className="col"
            style={
              width < 768
                ? {}
                : {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                  }
            }
          >
            <Card
              style={width < 850 ? { width: "100%" } : { width: "100%" }}
              className="bg-secondary shadow border-0"
            >
              <CardBody className="px-lg-5 py-lg-5">
                <div className="new_cabinet_page_title">
                  <h2> {isEditPage ? "Update Subject" : "Add Subject"}</h2>
                </div>

                <Form role="form" onSubmit={submitHandler} className="new_cabinet_form">
                  <FormGroup>
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-cabinet-title">
                            First Name
                          </label>
                          <Input
                            className="form-control-alternative"
                            required
                            onChange={(e) => setFirstName(e.target.value)}
                            value={firstName}
                            id="input-cabinet-title"
                            placeholder="First Name"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-cabinet-title">
                            Middle Name
                          </label>
                          <Input
                            className="form-control-alternative"
                            required
                            onChange={(e) => setMiddleName(e.target.value)}
                            value={middleName}
                            id="input-cabinet-title"
                            placeholder="Middle Name"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-cabinet-title">
                            Last Name
                          </label>
                          <Input
                            className="form-control-alternative"
                            required
                            onChange={(e) => setLastName(e.target.value)}
                            value={lastName}
                            id="input-cabinet-title"
                            placeholder="Last Name"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="8">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-cabinet-title">
                            Date of Birth
                          </label>
                          <Flatpickr
                            required={true}
                            value={dateOfBirth}
                            closeMenuOnSelect
                            options={{
                              dateFormat: "m/d/Y",
                              maxDate: new Date(),
                              disableMobile: true
                            }}
                            onChange={(e) => {
                              setDateOfBirth(e);
                            }}
                            className="flatpickr datetimepicker form-control"
                            style={{
                              boxShadow: "0 1px 3px rgb(50 50 93 / 15%), 0 1px 0 rgb(0 0 0 / 2%)",
                              border: "none",
                              backgroundColor: "#fff"
                            }}
                            placeholder="Select Date Time"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-cabinet-title">
                            Gender
                          </label>
                          <SelectSearch
                            handleChange={(e) => setGender(e)}
                            value={gender}
                            placeHolder="Select Gender"
                            options={[
                              {
                                id: 1,
                                value: "male",
                                label: "Male"
                              },
                              {
                                id: 2,
                                value: "female",
                                label: "Female"
                              },
                              {
                                id: 3,
                                value: "identifies as other",
                                label: "Identifies as other"
                              }
                            ]}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="mb-3">
                      <Col sm={12} className="d-flex justify-content-start align-items-center mt-3">
                        <span onClick={(e) => e.stopPropagation()}>
                          {testList?.length > 0
                            ? "Add New Assessment"
                            : "No Assessment available to assign"}
                        </span>
                        {testList?.length > 0 && (
                          <Button
                            type="button"
                            style={{
                              background: "transparent",
                              boxShadow: "none",
                              border: "none",
                              transition: "none",
                              padding: "0"
                            }}
                            disabled={testList?.length > 0 ? false : true}
                            onClick={() => {
                              if (tests.findIndex((item) => item.title === "") !== -1) {
                                toast("Please select assessment before you add new one");
                                return;
                              }
                              setTests((preData) => [
                                {
                                  id: uuidv4(),
                                  value: "",
                                  label: "",
                                  title: "",
                                  assessmentId: null,
                                  examinationPlace: "",
                                  examinationTime: "",
                                  type: ""
                                },
                                ...preData
                              ]);
                            }}
                          >
                            <i
                              className="fas fa-plus add_test_section_icon ml-2"
                              style={{
                                padding: "5px 7px",
                                trasition: "all 0.3s ease-in-out",
                                borderRadius: "5px"
                              }}
                            ></i>
                          </Button>
                        )}
                      </Col>
                    </Row>
                    {isEditPage || testList?.length > 0 ? (
                      <Row>
                        {tests.map((item) => {
                          const isInProgress = ["inprogress", "completed", "scored"].includes(
                            item.status
                          );
                          return (
                            <React.Fragment key={item.id}>
                              <Col lg={7} md={12} sm={12} xs={12} className="mt-2">
                                <AssessmentSelectionMiddleware
                                  menuItems={[...testSelectList]}
                                  selected={item}
                                  disabled={item?.isFixed}
                                  onSelectHandler={(selectedTest, newItem) => {
                                    setTests((preData) =>
                                      preData.map((test) => {
                                        if (test.id === selectedTest.id) {
                                          return {
                                            ...newItem,
                                            assessmentId: newItem.id,
                                            title: newItem.title,
                                            examinationPlace: "",
                                            examinationTime: "",
                                            type: "",
                                            id: test.id
                                          };
                                        }

                                        if (test.title === "") {
                                          return {
                                            ...test,
                                            ...newItem,
                                            assessmentId: newItem.id,
                                            title: newItem.title,
                                            id: test.id
                                          };
                                        }
                                        return test;
                                      })
                                    );
                                  }}
                                />
                              </Col>
                              <Col lg={4} md={8} sm={10} xs={10} className="mt-2">
                                <FormGroup>
                                  <FormMenu
                                    disabled={isInProgress}
                                    menuItems={testTypeOptions}
                                    onSelectHandler={(selectedTest, e) => {
                                      setTests((prevState) =>
                                        prevState.map((updateTest) => {
                                          if (updateTest.id === item.id) {
                                            updateTest.type = e;
                                          }
                                          return updateTest;
                                        })
                                      );
                                    }}
                                    selected={item?.type}
                                    placeHolder="Select Assessment Type"
                                  />
                                </FormGroup>
                              </Col>

                              {!isInProgress ? (
                                <Col
                                  lg={1}
                                  md={4}
                                  sm={2}
                                  xs={2}
                                  className="d-flex justify-content-center align-items-start mt-3"
                                >
                                  <Button
                                    type="button"
                                    style={{
                                      background: "transparent",
                                      boxShadow: "none",
                                      border: "none",
                                      transition: "none",
                                      padding: "0"
                                    }}
                                    onClick={() => {
                                      setTests((preData) =>
                                        preData.filter((subItem) => subItem.id !== item.id)
                                      );
                                    }}
                                  >
                                    <i
                                      className="fas fa-times remove_test_section_icon ml-2"
                                      style={{
                                        padding: "5px 7px",
                                        trasition: "all 0.3s ease-in-out",
                                        borderRadius: "5px"
                                      }}
                                    ></i>
                                  </Button>
                                </Col>
                              ) : null}

                              <Col sm={6}>
                                <FormGroup>
                                  <Flatpickr
                                    data-enable-time
                                    closeMenuOnSelect
                                    value={item?.examinationTime}
                                    options={{
                                      dateFormat: "m/d/Y h:i K",
                                      minDate: typeof item.id === "string" ? new Date() : null,
                                      enableTime: true,
                                      allowInvalidPreload: true,
                                      disableMobile: true
                                    }}
                                    required={true}
                                    disabled={isInProgress}
                                    onChange={(e) => {
                                      setTests((prevState) =>
                                        prevState.map((updateTest) => {
                                          const appointDate =
                                            moment(`${e}`).utc().format("YYYY-MM-DDTHH:mm:ss") +
                                            "Z";
                                          if (updateTest.id === item.id) {
                                            updateTest.examinationTime = appointDate;
                                          }
                                          return updateTest;
                                        })
                                      );
                                    }}
                                    className="flatpickr datetimepicker form-control"
                                    style={{
                                      boxShadow:
                                        "0 1px 3px rgb(50 50 93 / 15%), 0 1px 0 rgb(0 0 0 / 2%)",
                                      border: "none",
                                      backgroundColor: "#fff"
                                    }}
                                    placeholder="Assessment Date Time"
                                  />
                                </FormGroup>
                              </Col>

                              <Col sm={6}>
                                <FormGroup>
                                  <Input
                                    className="form-control-alternative"
                                    required
                                    disabled={isInProgress}
                                    onChange={(e) => {
                                      setTests((prevState) =>
                                        prevState.map((updateTest) => {
                                          if (updateTest.id === item.id) {
                                            updateTest.examinationPlace = e.target.value;
                                          }
                                          return updateTest;
                                        })
                                      );
                                    }}
                                    value={
                                      tests.find((test) => test.id === item.id)?.examinationPlace
                                    }
                                    id="input-cabinet-title"
                                    placeholder="Set your Assessment location..."
                                    type="textarea"
                                    rows="1"
                                  />
                                </FormGroup>
                              </Col>
                              <Col xs={12}>
                                <hr />
                              </Col>
                            </React.Fragment>
                          );
                        })}
                      </Row>
                    ) : null}
                  </FormGroup>
                  <div className="text-center">
                    {isEditPage ? (
                      <Button disabled={submitting} color="main-color" type="submit">
                        {`${submitting ? "Updating" : "Update"}`}
                      </Button>
                    ) : (
                      <Button disabled={submitting} color="main-color" type="submit">
                        {`${submitting ? "Saving" : "Save"}`}
                      </Button>
                    )}
                    {/* <Button disabled={testList?.length === 0 || submitting} color="main-color" type="submit">
                      {isEditPage
                        ? `${submitting ? "Updating" : "Update"}`
                        : `${submitting ? "Saving" : "Save"}`}
                    </Button> */}
                    <NavLink to="/admin/subjects" replace>
                      <Button style={{ backgroundColor: "red", color: "white" }} type="submit">
                        Cancel
                      </Button>
                    </NavLink>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default NewPatientPage;

/*eslint-disable*/
import React from "react";
import { debounce } from "lodash";
import { useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardHeader, CardFooter, Table, Container, Row, Button } from "reactstrap";

import useViewport from "hooks/useViewport";

import Header from "components/Headers/Header.jsx";
import TooltipIcon from "components/TooltipIcon/TooltipIcon";
import Search from "components/Search/Search";
import Loader from "components/Loader/Loader";
import ExportButton from "components/ExportButton/ExportButton";
import NewPagination from "components/NewPagination/NewPagination";

import { selectUser } from "redux/user/selectors";
import { selectPatients } from "redux/patients/selectors";
import {
  fetchPatientsExportDataThunkAction,
  fetchPatientsThunkAction
} from "redux/patients/actions";

const PatientsPage = () => {
  const [patientData, setPatientData] = React.useState([]);
  const [error, setError] = React.useState(null);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [postsPerPage] = React.useState(10);
  const [search, setsSearch] = React.useState("");
  const [middleSearch, setMiddleSearch] = React.useState("");
  const [exportedData, setExportedData] = React.useState([]);

  let history = useHistory();
  const { width } = useViewport();
  const dispatch = useDispatch();
  const { patients, isLoading, totalPatients, exportPatientData } = useSelector(selectPatients);
  const { token, loggedInUser } = useSelector(selectUser);

  const onSuccess = () => {
    setError(null);
  };

  const onError = (error) => {
    setError(error);
  };

  const onSearchHandler = (keyword) => {
    setCurrentPage(1);
    setsSearch(keyword);
  };

  const navigateToPatientDetailsPage = (id) => {
    history.push(`/admin/subject-details/${id}`);
  };

  const navigateToUpdateCabinet = (id) => {
    history.push(`/admin/subject/update/${id}`);
  };

  React.useEffect(() => {
    dispatch(
      fetchPatientsThunkAction(
        {
          search,
          limit: postsPerPage,
          page: currentPage
        },
        loggedInUser?.id,
        onSuccess,
        onError
      )
    );
  }, [currentPage, search, loggedInUser]);

  React.useEffect(() => {
    setPatientData(patients);
  }, [patients, patients?.length]);

  const debouncedSave = React.useCallback(
    debounce((searchText) => {
      setsSearch(searchText);
      setCurrentPage(1);
    }, 700),
    [] // will be created only once initially
  );

  React.useEffect(() => {
    debouncedSave(middleSearch);
  }, [middleSearch]);

  React.useEffect(() => {
    dispatch(fetchPatientsExportDataThunkAction(loggedInUser?.id));
  }, []);

  React.useEffect(() => {
    const formatedData = exportPatientData.map((item) => ({
      name: `${item.lastName}${" "}${item?.firstName}${" "}${item?.middleName[0].toUpperCase()}`,
      dob: item.dob,
      gender: item.gender[0].toUpperCase() + item.gender.slice(1)
    }));

    const csvData = formatedData.reduce((acc, item) => {
      const { name, dob, gender } = item;
      acc.push([name, dob, gender].join(","));
      return acc;
    }, []);

    setExportedData(csvData);
  }, [exportPatientData]);

  return (
    <>
      <Header />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card className="shadow" style={{ minHeight: "50vh" }}>
              <CardHeader
                className={width < 768 ? "border-0" : "border-0 d-flex justify-content-between"}
              >
                <div
                  style={
                    width < 768
                      ? {
                          display: "flex",
                          justifyContent: "space-between",
                          marginBottom: "10px"
                        }
                      : {}
                  }
                >
                  <h3 className="mb-0 mr-2">Subjects</h3>
                  <Link to="/admin/subject/add">
                    <Button color="main-color" type="button" className="add_cabinet_button mr-2">
                      Add Subject
                    </Button>
                  </Link>

                  <ExportButton
                    fileName="Subjects.csv"
                    headers={["Name, Date of Birth,Gender"]}
                    fileData={exportedData}
                  >
                    Export Data
                  </ExportButton>
                </div>
                <Search
                  onSubmitHandler={onSearchHandler}
                  search={middleSearch}
                  onChangeHandler={(value) => setMiddleSearch(value)}
                />
              </CardHeader>
              {isLoading ? (
                <React.Fragment>
                  <div className="post_error_loading">
                    <Loader />
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {error ? (
                    <React.Fragment>
                      <div className="post_error_loading">{error}</div>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Table className="align-items-center table-flush" responsive>
                        <thead className="thead-light">
                          <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Date of Birth</th>
                            <th scope="col">Gender</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {patientData?.length > 0 &&
                            patientData?.map((user) => {
                              return (
                                <tr
                                  key={user?.id}
                                  style={{
                                    cursor: "pointer"
                                  }}
                                >
                                  <td
                                    onClick={() => navigateToPatientDetailsPage(user?.id)}
                                    style={{
                                      textTransform: "capitalize"
                                    }}
                                  >
                                    {user?.lastName} {user?.firstName}{" "}
                                    {user?.middleName[0].toUpperCase()}
                                  </td>
                                  <td onClick={() => navigateToPatientDetailsPage(user?.id)}>
                                    {user?.dob}
                                  </td>
                                  <td
                                    onClick={() => navigateToPatientDetailsPage(user?.id)}
                                    className="text-capitalize"
                                  >
                                    {user?.gender ? user?.gender : "Male"}
                                  </td>
                                  <td className="">
                                    <TooltipIcon
                                      target={"editpatient" + user?.id}
                                      label="Edit"
                                      iconClass="fas fa-edit"
                                      onclickHandler={() => navigateToUpdateCabinet(user?.id)}
                                      style={{
                                        cursor: "pointer",
                                        marginLeft: "1rem",
                                        fontSize: "0.9rem"
                                      }}
                                    />
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </Table>
                      {!error && patientData?.length === 0 && (
                        <div className="no_posts">No subjects found.</div>
                      )}
                      {patientData && patientData?.length > 0 && (
                        <CardFooter className="py-4">
                          <nav aria-label="..." className="post_footer">
                            <NewPagination
                              totalRecords={totalPatients}
                              setCurrentPage={setCurrentPage}
                              pageLimit={postsPerPage}
                              currentPage={currentPage}
                              pageNeighbours={1}
                            />
                          </nav>
                        </CardFooter>
                      )}
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default PatientsPage;

import React from "react";
import makeAnimated from "react-select/animated";
import { Box, Grid, Paper } from "@material-ui/core";
import { Col, FormGroup, Input, Row } from "reactstrap";

import { styles } from "../common/styles";
import { renderButton, renderText } from "../common/DisplayComponent";

const Step4 = ({
  isViewPage,
  submitting,
  stepCount,
  resultData,
  handleNext,
  handlePrev,
  isAssessmentCompleted
}) => {
  const animatedComponents = makeAnimated();

  const optionPrefix = ["A", "B", "C", "D", "E", "F"];
  return (
    <Paper style={styles.steps}>
      <Box mt={2} mb={2}>
        {renderText({
          label: "Mixed Type",
          type: "h6",
          color: "textPrimary",
          align: "center"
        })}
      </Box>
      <Row>
        <Col sm="12">
          <div>
            <h3>{stepCount?.title}</h3>
          </div>
        </Col>
        <Col sm="12">
          <div>
            <p>{stepCount?.description}</p>
          </div>
        </Col>
      </Row>

      <hr />

      <Row>
        {stepCount?.questions?.map((item, index) => {
          const isCorrectAnswer = item.answer === item.subjectAnswer ? true : false;

          const questionAnswer = resultData?.find(
            (answer) => answer.id === item?.id
          )?.subjectAnswer;

          return (
            <React.Fragment key={item?.id}>
              <Col sm="10">
                <div>
                  <p>
                    <b>{item?.title}</b>
                  </p>
                </div>
              </Col>

              {item?.type === "text" ? (
                <Col sm={12}>
                  <FormGroup>
                    <Input
                      className="form-control-alternative"
                      required
                      disabled
                      value={resultData.find((question) => question.id === item?.id)?.subjectAnswer}
                      id="input-cabinet-title"
                      type="textarea"
                    />
                  </FormGroup>
                </Col>
              ) : null}

              {item?.type !== "text" &&
                item?.options?.map((option, index) => {
                  const isUserSelectedOPtion = questionAnswer === option?.value;
                  return (
                    <React.Fragment key={item?.id + option?.id}>
                      <Col xs={10} sm={7}>
                        <div
                          className={isUserSelectedOPtion ? "option_item_active" : "option_item"}
                        >
                          <div className="option_prefix">{optionPrefix[index]}</div>
                          {option?.value?.charAt(0).toUpperCase() + option?.value?.slice(1)}
                        </div>
                      </Col>
                      <Col xs={1} sm={1}>
                        {isUserSelectedOPtion ? (
                          isCorrectAnswer ? (
                            <i className="fas fa-check answer-check"></i>
                          ) : (
                            <i className="fas fa-times answer-times"></i>
                          )
                        ) : null}
                      </Col>
                    </React.Fragment>
                  );
                })}

              <Col sm={8}>
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-cabinet-title">
                    Item Score
                  </label>
                  <Input
                    disabled
                    className="form-control-alternative"
                    min={0}
                    max={resultData.find((question) => question.id === item?.id)?.score}
                    value={resultData.find((question) => question.id === item?.id)?.subjectScore}
                    id="input-cabinet-title"
                    type="number"
                    step={`${item?.type === "text" ? 0.01 : 1}`}
                  />
                </FormGroup>
              </Col>

              <Col sm={4}>
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-cabinet-title">
                    Max Score
                  </label>
                  <Input
                    className="form-control-alternative"
                    disabled
                    value={resultData.find((question) => question.id === item?.id)?.score}
                    id="input-cabinet-title"
                    type="number"
                  />
                </FormGroup>
              </Col>

              <Col xs={12}>
                <hr />
              </Col>
            </React.Fragment>
          );
        })}
      </Row>

      <Grid container component={Box} justify="flex-end" mt={2} p={2}>
        {stepCount?.prev && (
          <Box ml={2}>
            {renderButton({
              label: "Back",
              color: "default",
              onClick: handlePrev
            })}
          </Box>
        )}
        {stepCount?.next && (
          <Box ml={2}>
            {renderButton({
              label: "Next",
              onClick: () => {
                handleNext(stepCount);
              }
            })}
          </Box>
        )}
        {stepCount?.submit && isAssessmentCompleted && (
          <Box ml={2}>
            {renderButton({
              label: submitting ? "Verifying..." : "Verify",
              onClick: () => {},
              type: "submit"
            })}
          </Box>
        )}
      </Grid>
    </Paper>
  );
};

export default Step4;

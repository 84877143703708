/*eslint-disable*/
import React from "react";
import { useSelector } from "react-redux";
import { Card, CardHeader, Container, Row, CardBody, Col } from "reactstrap";

import useViewport from "hooks/useViewport";

import Header from "components/Headers/Header.jsx";

import { selectUser } from "redux/user/selectors";

import "../assets/css/PatientDetailsPage.css";
import { phoneFormat } from "utils/getSection";

const ProfilePage = () => {
  const { width } = useViewport();
  const { role: userRole, loggedInUser } = useSelector(selectUser);

  return (
    <>
      <Header />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card className="shadow" style={{ minHeight: "50vh" }}>
              <CardHeader
                className={width < 768 ? "border-0" : "border-0 d-flex justify-content-between"}
              >
                <h3 className="mb-0">Profile Details</h3>
              </CardHeader>
              <CardBody className="">
                <React.Fragment>
                  <Row className="mb-4">
                    <Col sm={5} xs={6}>
                      <b>First Name:</b>
                    </Col>
                    <Col>
                      <span>{loggedInUser?.firstName}</span>
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col sm={5} xs={6}>
                      <b>Last Name:</b>
                    </Col>
                    <Col>
                      <span>{loggedInUser?.lastName}</span>
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col sm={5} xs={6}>
                      <b>Email:</b>
                    </Col>
                    <Col>
                      <span>{loggedInUser?.email}</span>
                    </Col>
                  </Row>
                  <Row className="mb-4">
                    <Col sm={5} xs={6}>
                      <b>Phone Number:</b>
                    </Col>
                    <Col>
                      <span>{phoneFormat(loggedInUser?.mobile)}</span>
                    </Col>
                  </Row>
                </React.Fragment>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ProfilePage;

import React from "react";

const usePasswordShow = () => {
  const [passwordShown, setPasswordShown] = React.useState(false);

  const togglePasswordVisiblity = () => {
    setPasswordShown((preValue) => !preValue);
  };

  const type = passwordShown ? "text" : "password";

  return { passwordShown, togglePasswordVisiblity, type };
};

export default usePasswordShow;

export const booleanQuestionTypeOptions = [
  {
    id: "true",
    value: "true",
    label: "True"
  },
  {
    id: "false",
    value: "false",
    label: "False"
  }
];

export const questionTypesOptions = [
  {
    id: 1,
    type: "text",
    value: "open Ended Questions",
    label: "Open Ended",
    title: "",
    description: "",
    correctAnswerFeedback: "",
    incorrectAnswerFeedback: "",
    correctAnswerFeedbackAudio: "",
    incorrectAnswerFeedbackAudio: "",
    next: true,
    prev: true,
    submit: false,
    questions: [
      {
        id: 0,
        type: "text",
        title: "",
        answer: "",
        score: null,
        options: null,
        correctAnswerFeedback: "",
        incorrectAnswerFeedback: ""
      }
    ]
  },
  {
    id: 2,
    type: "true-false",
    value: "true/False",
    label: "True/False",
    title: "",
    description: "",
    correctAnswerFeedback: "",
    incorrectAnswerFeedback: "",
    correctAnswerFeedbackAudio: "",
    incorrectAnswerFeedbackAudio: "",
    next: true,
    prev: true,
    submit: false,
    questions: [
      {
        id: 0,
        type: "true-false",
        title: "",
        answer: "",
        score: null,
        options: [
          ...booleanQuestionTypeOptions,
          {
            id: "No Response",
            value: "No Response",
            label: "No Response"
          }
        ],
        correctAnswerFeedback: "",
        incorrectAnswerFeedback: ""
      }
    ]
  },
  {
    id: 3,
    type: "mcq",
    value: "multiple Choice Questions",
    label: "Multiple Choice",
    title: "",
    description: "",
    correctAnswerFeedback: "",
    incorrectAnswerFeedback: "",
    correctAnswerFeedbackAudio: "",
    incorrectAnswerFeedbackAudio: "",
    next: true,
    prev: true,
    submit: false,
    questions: [
      {
        id: 0,
        type: "mcq",
        title: "",
        answer: "",
        score: null,
        options: [
          {
            id: "0",
            label: "",
            value: ""
          },
          {
            id: "No Response",
            value: "No Response",
            label: "No Response"
          }
        ],
        correctAnswerFeedback: "",
        incorrectAnswerFeedback: ""
      }
    ]
  },
  {
    id: 4,
    type: "mixed",
    value: "Mixed Type Questions",
    label: "Mixed Type",
    title: "",
    description: "",
    correctAnswerFeedback: "",
    incorrectAnswerFeedback: "",
    correctAnswerFeedbackAudio: "",
    incorrectAnswerFeedbackAudio: "",
    next: true,
    prev: true,
    submit: false,
    questions: []
  }
];

export const testStatusOptions = [
  {
    id: 2,
    label: "Active",
    value: true,
    disabled: false,
    selected: false
  },
  {
    id: 3,
    label: "Inactive",
    value: false,
    disabled: false,
    selected: false
  }
];

export const testTypeOptions = [
  {
    id: 2,
    label: "App",
    value: "app",
    title: "app"
  },
  {
    id: 3,
    label: "Computer",
    value: "computer",
    title: "computer"
  }
];

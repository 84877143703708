import {
  ADD_NEW_QUESTION,
  FETCH_TEST_EXPORT_DATA,
  FETCH_TEST_FAILURE,
  FETCH_TEST_LIST_OPTIONS,
  FETCH_TEST_REQUEST,
  FETCH_TEST_SUCCESS,
  RESET_TEST_DATA,
  SELECTED_TEST,
  SET_TEST_QUESTION_TYPES
} from "./types";

const initialState = {
  isLoading: false,
  error: "",
  newTestQuestionTypes: [],
  newTestTitle: "",
  tests: [],
  totalTests: 0,
  selectedTest: {},
  exportTestData: [],
  testList: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TEST_REQUEST:
      return {
        ...state,
        newTestQuestionTypes: [],
        selectedTest: {},
        newTestTitle: "",
        isLoading: true
      };
    case FETCH_TEST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        tests: action.payload.users,
        totalTests: action.payload.totalUsers,
        error: ""
      };

    case FETCH_TEST_EXPORT_DATA:
      return {
        ...state,
        exportTestData: action.payload
      };
    case FETCH_TEST_FAILURE:
      return {
        ...state,
        isLoading: false,
        tests: [],
        error: action.payload
      };
    case SET_TEST_QUESTION_TYPES:
      return {
        ...state,
        newTestQuestionTypes: action.payload.typeArray,
        newTestTitle: action.payload.testTitle
      };
    case ADD_NEW_QUESTION:
      return {
        ...state,
        newTestQuestionTypes: action.payload
      };
    case SELECTED_TEST:
      return {
        ...state,
        newTestTitle: action.payload.title,
        newTestQuestionTypes: action.payload.sections,
        selectedTest: action.payload.test
      };

    case FETCH_TEST_LIST_OPTIONS:
      return {
        ...state,
        testList: action.payload
      };
    case RESET_TEST_DATA:
      return {
        ...initialState,
        newTestQuestionTypes: [],
        selectedTest: {},
        newTestTitle: ""
      };
    default:
      return state;
  }
};

export default reducer;
